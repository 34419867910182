
<div class="card">
  <div class="header-custom">
    <div class="row">
      <div class="form-group col-sm-6">
        <h1 class="">{{examName}}</h1>

      </div>

    </div>
  </div>

<div class="row examBody">
<fieldset class="form-group col-sm-8 " >
 <div class=" m-t-10" align="end">
   <button class="btn btn-primary m-r-5" (click)="editOpen(questionList[currentindex])">
    <i class="feather icon-edit"></i>
    Edit a Question</button>
   <button class="btn btn-Refresh" (click)="cancel()">Refresh</button>
 </div>
  <div class="m-l-10" *ngIf="!editQuestionView">

    <label class="form-row1 "> {{ currentindex +1 }})  {{ questionList[currentindex].Question }}
      ?</label>
    <label class="form-row ">A)  {{ questionList[currentindex].Option1 }}
    </label>
    <label class="form-row ">B)  {{ questionList[currentindex].Option2 }}
    </label>
    <label class="form-row ">C)  {{ questionList[currentindex].Option3 }}
    </label>
    <label class="form-row ">D) {{ questionList[currentindex].Option4 }}
    </label>
    <label class="form-row "  *ngIf="questionList[currentindex].Option5 '!=null'">E)  {{ questionList[currentindex].Option5 }}
    </label>
    <label class="form-row  nextSave-btn">Correct Option: {{ questionList[currentindex].CorrectOption }} </label>
    <label class="form-row ">
      Description: {{ questionList[currentindex].Description}}.</label>
  </div>
  <div *ngIf="editQuestionView">
    <div  class="form-row ">
      <label class="col-sm-2 col-form-label">Question: </label>
        <input type="text" class="col-sm-10  form-control" name="Question"[(ngModel)]="previewData.Question">

    </div>
    <div class="form-row ">
      <label class="col-sm-2 col-form-label">Option1:</label>
      <input type="text" class="col-sm-10  form-control" name="Option1"[(ngModel)]="previewData.Option1">
   </div>
    <div class="form-row ">
       <label class="col-sm-2 col-form-label">Option2:</label>
         <input type="text" class="col-sm-10  form-control" name="Option2"[(ngModel)]="previewData.Option2">
    </div>
    <div class="form-row ">
       <label class="col-sm-2 col-form-label">Option3:</label>
         <input type="text" class="col-sm-10  form-control" name="Option3"[(ngModel)]="previewData.Option3">
    </div>
    <div class="form-row ">
      <label class="col-sm-2 col-form-label">Option4:</label>
    <input type="text" class="col-sm-10  form-control " name="Option4"[(ngModel)]="previewData.Option4">
    </div>
    <div class="form-row " *ngIf="questionList[currentindex].Option5 '!=null'">
      <label  class="col-sm-2 col-form-label">Option5:</label>
        <input type="text" class="col-sm-10  form-control" name="Option5"[(ngModel)]="previewData.Option5">
       </div>
    <div class="form-row ">
      <label class="col-sm-2 col-form-label">Correct Option:</label>
        <input type="text" class=" col-sm-10 form-control" name="CorrectOption"[(ngModel)]="previewData.CorrectOption">
 </div>
    <div class="form-row ">

    <label class="col-sm-2 col-form-label">Description:</label>
    <input type="text" class="col-sm-10  form-control" name="Description"[(ngModel)]="previewData.Description">

    </div>
    <div class="form-row" align="end">
      <label class="col-sm-9"></label>
      <div class=" m-t-15 col-sm-3" >
        <button class="btn btn-primary m-r-5" (click)="save()">Submit</button>
        <button class="btn btn-danger" (click)="cancel()">cancel</button>
      </div>
    </div>





  </div>
</fieldset>
    <div class=" col-sm-4 custom-bag-exam">
     <div class="">
      <div class="btn-group nextBtn " *ngFor="let item of questionList; let i = index">


        <button class="btn" type="button "
        [style.background-color]="getBackgroundColor(i)"
        (click)="onQuestion(i)">
          {{ i + 1 }}

        </button>

      </div>
     </div>

    </div>
</div>
<div class="examFooter">
  <div class="row">
    <div class="col-md-7">
      <div class="btn-group" >


        <button type="button" *ngIf="currentindex !== 0" class="btn  btn-primary m-r-20" (click)="onBack()">Back</button>


        <button type="button" [disabled]="currentindex  >= questionList.length -1 "
          class="btn  nextSave-btn m-r-20" (click)="onNextClick()">
          Next
        </button>
        <button type="button"
          class="btn back-btn  m-l-20" (click)="onBackList()">
          Exit
        </button>



      </div>
    </div>
    <div class="col-md-5" align="end">

    </div>
  </div>
</div>
</div>
