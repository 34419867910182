import {Component, OnInit} from '@angular/core';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user/user-service.service';
import { EndpointService } from 'src/app/services/EndPoint/endpoint.service';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavRightComponent implements OnInit {
userList:any;
  notificationList: any;
 /// notificationId: any;
 badgeContent:number;
  constructor(  private router:Router,private service: UserService,private endpoint:EndpointService,) { }
 Uname = localStorage.getItem("firstName");
uId = localStorage.getItem("userId");
imgUrl="assets/images/user/user1.jpg";
imgUrl1:any;
 urlpath = this.endpoint.backendUrl;
ngOnInit() {
      this.getuserbyId(this.uId)
      this.getNotificationById(this.uId)

  }
  logout(){
     localStorage.removeItem('token');
         localStorage.removeItem('userType');
         localStorage.removeItem('userId');
         localStorage.removeItem('userName');
         localStorage.removeItem('firstName');
         localStorage.removeItem('email');
         this.router.navigate(['']);
         localStorage.removeItem('lastName');
         localStorage.removeItem('userTest');
  }
   getuserbyId(uId) {
    this.service.getUserById(uId).subscribe((result) => {
      let respo = <any>result;
      this.userList=respo;

      //this.imgUrl=respo.filePath;
    // debugger;
      if(respo.filePath ===''){
      this.imgUrl1="assets/images/user/user1.jpg";
     }
     else{
       this.imgUrl1=this.urlpath+respo.filePath;

     }

      //console.log(this.userList, 'List');
    });
}
   getNotificationById(uId) {
    this.service.getNotificationBYId(uId).subscribe((result) => {
      let respo = <any>result;
      this.notificationList=respo.data;
      //this.notificationId=respo.data.id;
      //console.log(this.notificationList, 'NotList');
     // if(this.notificationList[Uid])
     // console.log(this.notificationList, ' Id');
      // this.badgeContent=this.notificationList.length;
       this.badgeContent=this.notificationList.filter(item =>!item.isRead).length;
    });
  }
 resadNotification(id:any,i:any){
    this.service.resadnotification(id).subscribe(result => {

//resadnotification
    })
  }
  readMessage(id:any,i:any){
     this.service.resadnotification(id).subscribe(result => {
      Swal.fire({
      title: `${this.notificationList[i].title}`,
      text: `${this.notificationList[i].notification}`,
      type: 'success',
      showCloseButton: true,
      // showCancelButton: true
    });
    this.getNotificationById(this.uId)
    });
  }
}

