import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EndpointService {

  getpasswordbyid(getpasswordbyid: any, id: any, options: { headers: import("@angular/common/http").HttpHeaders; }) {
    throw new Error('Method not implemented.');
  }
  host:string="https://lms.ndtstudies.com/api/"
  //host:string="http://65.2.65.159:3000/";
  //host: string = "http://localhost:3000/";

  baseURL = this.host + "api/";
  backendUrl = this.host + "api/"
  userbase = this.baseURL + "users/"
  moduleBase = this.baseURL + "module/";
  coursebase = this.baseURL + "course/";
  subjectbase = this.baseURL + "subject/"
  unitbase = this.baseURL + "unit/"
  studyMaterial = this.baseURL + "meterial/"
  exams = this.baseURL + "exams/"
  userCourse = this.baseURL + "userCourse/"
  notification = this.baseURL + "notification/"
  dashboard = this.baseURL + "dashboard/"
  forgetpassword = this.baseURL + "forgetpassword/"
  resetpassword = this.baseURL + "resetpassword/"
  account = this.baseURL + "account/"
  feedback = this.baseURL + "feedback/";
  advertise = this.baseURL + "advertise/";
  trainingShedule = this.baseURL + "trainingShedule/"
  ///api/dashboard/getUserListFordashboard
  //login

  readonly login = this.userbase + "login";

  //User create delete get edit
  readonly createUser = this.userbase + "createUser";
  readonly userRegister = this.userbase + "register";
  readonly getAllUser = this.userbase + "getAllUser";
  readonly getUserById = this.userbase + "getUser/";
  readonly deleteUserById = this.userbase + "deleteUser/";
  readonly updateUserById = this.userbase + 'updateUser/';


  //// MOdule create delete get edit
  readonly createModule = this.moduleBase + "createModule";
  readonly getAllModule = this.moduleBase + "getAllmodule";
  readonly getMOduleById = this.moduleBase + "getmoduleById/";
  readonly updateModuleById = this.moduleBase + "updateModule/";
  readonly getMOduleTree = this.moduleBase + "getmoduleTree/";
  readonly deleteModuleById = this.moduleBase + "deleteModule/";

  //// course create delete get edit
  readonly createCourse = this.coursebase + "createCourse";
  readonly getAllCourses = this.coursebase + "getAllCourses";

  readonly getCourseById = this.coursebase + "getCourseById/";
  readonly getCourseByModule = this.coursebase + "getCourseByModule/";
  readonly updateCourseById = this.coursebase + "updateCourse/";
  readonly deleteCourseById = this.coursebase + "deleteCourse/";


  //// subject  create delete get edit
  readonly createSubject = this.subjectbase + "createSubject";
  readonly getAllSubjects = this.subjectbase + "getAllSubjects";
  readonly getSubjectById = this.subjectbase + "getSubjectsByCourse/";
  readonly updateSubjectById = this.subjectbase + "updateSubject/";
  readonly deleteSubjectById = this.subjectbase + "deleteSubject/";

  //// subject  create delete get edit
  readonly createUnit = this.subjectbase + "createUnit";
  readonly getAllUnits = this.subjectbase + "getAllUnits";
  readonly getUnitById = this.subjectbase + "getUnitsBySubject/";
  readonly updateUnitById = this.subjectbase + "updateUnit/";
  readonly deleteUnitById = this.subjectbase + "deleteUnit/";

  ////studay material
  readonly createStudyMaterial = this.studyMaterial + "createMaterial";
  readonly getAllStudyMaterial = this.studyMaterial + "getMeterials";
  readonly filtereMeterials = this.studyMaterial + 'filtereMeterials';
  readonly updateMaterialById = this.studyMaterial + "editMeterial/";
  readonly getMeterialByUser = this.userCourse + "getMeterialByUser/";
  readonly deleteMeterials = this.studyMaterial + "deleteMeterial/"
  ////exa
  readonly uploadQuestions = this.exams + "uploadQuestions";
  readonly editQuestion = this.exams + "editQuestion";
  readonly getAllExams = this.exams + "getAllQBExam";
  readonly getExamsById = this.exams + "getExamsById/";
  //exams/saveExamResults getExamResultByuser/:idgetAllExamResult
  readonly saveExamResults = this.exams + "saveExamResults";
  readonly getAllExamResultByuser = this.exams + "getExamResultByuser/";
  readonly getExamResultById = this.exams + "getExamResultById/";
  readonly getAllExamResult = this.exams + "getAllExamResult";
  readonly FilterExam = this.exams + 'FilterExam';
  readonly filterResult = this.exams + 'filterResult';
  readonly deleteExam = this.exams + 'deleteExam/';
  readonly deleteEamResult = this.exams + 'deleteEamResult/';
  //single edam
  readonly createQuestionBank = this.exams + 'createQuestionBank';
  readonly deleteQuestionBank = this.exams + 'deleteQuestionBank/';
  readonly editQuestionBank = this.exams + 'editQuestionBank';
  readonly deleteMultipleQuestionBank = this.exams + 'deleteMultipleQuestionBank';

  ////assignCourse userCourses
  readonly assignCourse = this.userCourse + "assignCourses";
  readonly getAllAssignourse = this.userCourse + "getAssignedCourses";
  readonly getUserCoursesUId = this.userCourse + "getUserCourses/";
  readonly getCourseTree = this.userCourse + "getUserCourseTree/";
  readonly getUserExams = this.userCourse + "getUserExams/";
  readonly getUserQBpracticeExams = this.userCourse + "getUserQBExams/";
  readonly getUsertoApprove = this.userCourse + "getCoursesToUprove";
  readonly editAssignCourses = this.userCourse + "editAssignCourses/";
  readonly deleteAssignCourses = this.userCourse + "deleteAssignCourses/"
  /////////Notification
  readonly createNotification = this.notification + "createNotification";
  readonly getNotifations = this.notification + "getNotifations/";
  readonly getAllNotifations = this.notification + "getAllNotifations/";
  readonly resadnotification = this.notification + "resadnotification/";
  readonly deletenotification = this.notification + "delete/";


  /////submitUserApproval

  readonly submitUserApproval = this.userCourse + "approveCourse";



  ////Dashboard  create delete get edit

  readonly getAdminDashboard = this.dashboard + "getAdminDetails"
  readonly getUserDashboard = this.dashboard + "getUserDashboard/"
  readonly getUserListFordashboard = this.dashboard + "getUserListFordashboard"
  /// forget password
  readonly getpasswordbyId = this.forgetpassword + "getpasswordbyId/"

  // reset password
  readonly resetPasswordUrl = this.resetpassword + "resetpassword"
  /////filter exams/FilterExam
  readonly resetAdminPassword = this.userbase + "resetPassword"

  ///createaccount
  readonly createaccount = this.account + "createaccount"
  readonly getAllAccount = this.account + "getAllAccount"
  readonly editAccount = this.account + "editAccount"
  readonly getPermissionsByName = this.account + "getPermissionsByName/"


  //getCourseTreeForAdmin
  readonly getCourseTreeForAdmin = this.moduleBase + 'getCourseTreeForAdmin'

  ///changeUnitOrder
  readonly changeUnitOrder = this.subjectbase + 'changeUnitOrder'
  readonly changeSubjectOrder = this.subjectbase + 'changeSubjectOrder'

  /// FeedBack
  readonly createFeedBack = this.feedback + 'createFeedBack'
  readonly getAllFeedback = this.feedback + 'getAllFeedback'
  readonly getFeedbackById = this.feedback + 'getFeedbackById/'
  /// sharingExperiance
  readonly sharingExperianceByUser = this.feedback + 'sharingExperiance'
  readonly sharingExperianceByAdmin = this.feedback + 'sharingExperianceByAdmin'

  readonly getAllExperience = this.feedback + 'getAllExperience'
  readonly getExperienceByUser = this.feedback + 'getExperienceByUser/'
  readonly getExperianceToApprove = this.feedback + 'getExperianceToApprove'
  readonly getExperience = this.feedback + 'getExperience/';
  readonly approveExperiance = this.feedback + 'approveExperiance/';
  readonly RejectExperiance = this.feedback + 'RejectExperiance/';


  ///getCertificate
  readonly getCertificate = this.userCourse + 'getCertificateCourses/';
  readonly getUserCoursesById = this.userCourse + 'getUserCoursesById/';

  ///trainingShedule
  readonly createShedule = this.trainingShedule + 'createShedule';
  readonly createSheduleByAdmin = this.trainingShedule + 'createSheduleByAdmin';
  readonly getAllShedules = this.trainingShedule + 'getAllShedules';
  readonly getSheduleforApprove = this.trainingShedule + 'getSheduleforApprove';
  readonly getApprovedShedule = this.trainingShedule + 'getApprovedShedule';
  readonly getAppointmentUserId = this.trainingShedule + 'getSheduleforuser/';
  readonly approveShedule = this.trainingShedule + 'approveShedule/';
  readonly deleteShedule = this.trainingShedule + 'deleteShedule/';

  ///createQBExam
  readonly createQBExam = this.exams + 'createQBExam';
  readonly getAllQBExam = this.exams + 'getAllQBExam';
  readonly getQBExam = this.exams + 'getQBExam/';
  readonly getQBExamQuestions = this.exams + 'getQBExamQuestions/';
  readonly getAllQuestionBank = this.exams + 'getAllQuestionBank';
  readonly getQuestionBankByunit = this.exams + 'getQuestionBankByunit/'
  readonly getQuestionBankBycourse = this.exams + 'getQuestionBankBycourse/'
  readonly getQuestionBankBySubject = this.exams + 'getQuestionBankBySubject/'
  readonly deleteQBExam = this.exams + 'deleteQBExam/'
  readonly getUserQBExams = this.userCourse + 'getUserQBExams/';
  readonly filterQbExam = this.exams + 'filterQbExam';

  ////password
  readonly requestPasswordReset = this.userbase + 'requestPasswordReset';
  readonly getAllResetRequests = this.userbase + 'getAllResetRequests';

  ///Advertisement
  readonly createAdvertisement = this.advertise + 'createAdvertisement'
  readonly getAllAdvertisement = this.advertise + 'getAllAdvertisements';
  readonly getAdvertisementById = this.advertise + 'getAdvertisementByUser/';
  readonly getAdvertisement = this.advertise + 'getAllAdvertisements';
  readonly deleteAdvertisement = this.advertise + "deleteAdvertisement/";
  ///
  readonly DeleteFeedbackById = this.feedback + "deleteFeedbackById/";
  //readonly deleteShedule=this.trainingShedule+"deleteShedule/";

  ///mcqQuestion
  readonly getAllQuestion = this.exams + 'getAllQuestion'

  //////
  readonly createQuestionBankfromExcel = this.exams + "createQuestionBankfromExcel";

}
