import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/services/Auth-Service/AuthenticationService.service';
import { UserService } from 'src/app/services/user/user-service.service';
import { Location } from '@angular/common'
import { EndpointService } from 'src/app/services/EndPoint/endpoint.service';

@Component({
  selector: 'app-cbtqb-exam',
  templateUrl: './cbtqb-exam.component.html',
  styleUrls: ['./cbtqb-exam.component.scss']
})
export class CBTQbExamComponent implements OnInit {
  examId: number;
  questionArray:any;
  questionList:any[]=[];
  selectedoption = '';
  currentindex = 0;

  chosenAnswer;
  IsOneWord:boolean=false;
  urlpath = this.endpoint.backendUrl;

  constructor(  private router: Router, private location: Location,private endpoint:EndpointService,
  private route: ActivatedRoute,private authService:AuthenticationService,private service:UserService) {

  }

ngOnInit(): void {
  // this.route.params.subscribe(params => {
  //   this.examId=params.id!=undefined?params.id:0;
  //   this.getExamsById(this.examId);
  //   //console.log(params.id)
  // })
        this.authService.QuestionBankExamId.subscribe(data => {
      if (data) {

        this.examId=data;

      }
    });
    this.getExamsById(this.examId);

}
   getExamsById(examId) {
    this.service.getQBExam(examId).subscribe(result => {
     let respo = <any>result;
     this.questionArray = respo.data;
     this.questionList = respo.data.question;
   //  //console.log(this.questionArray,'questiomb')
     ////console.log(this.questionList,'questiomb')

    if( this.questionList[0].NotVisited==true){
     }
    })

  }
  onSelect(selectedoption:any){
    //console.log(selectedoption,'kjhgf')
      this.questionList[this.currentindex].question.chosenAnswer=selectedoption;
  }


   onSaveNextClick() {
    this.IsOneWord=false;
    if(this.selectedoption!=null){

      this.questionList[this.currentindex].question.UnAnswered=false;
       this.questionList[this.currentindex].question.Answered=true;
        this.questionList[this.currentindex].question.NotVisited=false;
    //  this.questionList[this.currentindex].question.ReviewLater=false;
      //////console.log('currentindex',this.questionList)
    //  //console.log('befor', this.selectedoption);
      this.questionList[this.currentindex].question.chosenAnswer=this.selectedoption;
     /// //console.log('cg', this.questionList[this.currentindex].question.chosenAnswer);
      ////console.log('after', this.selectedoption);
     this.selectedoption = null;
      this.currentindex++;

    }
    else{
      this.currentindex++;
      this.questionList[this.currentindex].question.NotVisited=false;
      this.questionList[this.currentindex].question.UnAnswered=true;
    }

    }
    onQuestion(i:any){

      //  //////console.log(i,'index');

        this.currentindex= i;
        //////console.log('selectedObj',this.currentindex);

      this.selectedoption=this.questionList[this.currentindex].question.chosenAnswer;

      if(this.selectedoption!=null){

       this.questionList[this.currentindex].question.Answered=true;
       this.questionList[this.currentindex].question.NotVisited=false;


      }
      else{
        this.questionList[this.currentindex].question.UnAnswered=true;
        this.questionList[this.currentindex].question.NotVisited=false;
      }
      if(this.questionList[this.currentindex].question.chosenAnswer!=null){
        this.questionList[this.currentindex].question.Answered=true;
        this.questionList[this.currentindex].question.NotVisited=false;

      }
      // //////console.log('currentindex',this.questionList)
      }
    getBackgroundColor(i:any){
      // debugger;
        // //////console.log(i,'i')

        if(this.questionList[i].question.CorrectOption==this.questionList[i].question.chosenAnswer){
          return  '#449d44';
        }
        else  if(this.questionList[i].question.chosenAnswer==null){
          return '#000000';

        }
        else if (this.questionList[i].question.answer== this.questionList[i].question.chosenAnswer){
          return  '#449d44';
        }

        else{
          return '#c9302c';

        }


       }
    getColor(i:any,option:any){
      // debugger;
        // //////console.log(i,'i')
        if(this.selectedoption!=null){
         var styles;
          if(this.questionList[i].question.CorrectOption==this.questionList[i].question.chosenAnswer && this.questionList[i].question.chosenAnswer==option){
            styles = {

              'backgroundColor': 'green',
              'color': 'white'
            }
           // return  #449d44'';
            return styles;
          }
          else  if(this.questionList[i].question.chosenAnswer==null || this.questionList[i].question.chosenAnswer!=option){
             styles = {

              'backgroundColor': 'white',
              'color': 'black'
            }
          }
          else{
             styles = {

              'backgroundColor': 'red',
              'color': 'white',


            }
          // //console.log("this.questionList[i].question.chosenAnswer",this.questionList[i].question.chosenAnswer)
          // //console.log("this.questionList[i].question.chosenAnswer",this.questionList[i].question.CorrectOption)
            //return '#c9302c';

          }
          return styles
        }
       }

       submtExam(){
         //console.log('data',this.questionList)
       }
       onBack(){
        ////////console.log(this.currentindex);
        ///debugger;
         if(this.selectedoption!=null){
         }
         this.currentindex--;
        this.selectedoption=this.questionList[this.currentindex].question.chosenAnswer;
      }
  onBackList(){
  //  this.router.navigate(['user/QBpractice-List']);
  this.location.back();
  this.questionArray=null;
  this.questionList=[];
  }
  onewordSumbit(data,c){
   // this.IsOneWord=true
   //console.log('da',data)
   let answe=data.answer.trim().toLowerCase();
   let chosenAnswer=data.chosenAnswer.trim().toLowerCase();
   if(answe==chosenAnswer){
     this.questionList[this.currentindex].question.Answered=true
   }
   else{
    this.IsOneWord=true;
    this.questionList[this.currentindex].question.UnAnswered=true
   }
  }
}
