import {Injectable} from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {

  children?: NavigationItem[];
}

const NavigationItems = [

  /**/
  {
    id: 'Home',
    title: 'Home',
    type: 'group',
    icon: 'feather icon-align-left',
    children: [
      {
        id: 'Profile',
        title: 'Profile',
        type: 'collapse',
        icon: 'feather icon-home',
        children: [
          {
            id: 'adminhome',
            title: 'Dashboard',
            type: 'item',

            url: '/admin/adminhome',

          },
          {
            id: 'createuser',
            title: 'Create User',
            type: 'item',
            url: '/admin/newuserreg',
          },
          {
            id: 'AdminList',
            title: 'Manage Admin',
            type: 'item',
            url: '/admin/admin-list',
          },
          {
            id: 'MentorList',
            title: 'Manage Mentor',
            type: 'item',
            url: '/admin/mentor-list',
          },
          {
            id: 'user List',
            title: 'Manage User',
            type: 'item',
            url: '/admin/userlist',
          },
          {
            id: 'approveUser ',
            title: 'Approve User',
            type: 'item',
            url: '/admin/approve-user',
          }
          ,
          {
            id: 'accountSetting',
            title: 'Account Setting',
            type: 'item',
            url: '/admin/account',
          },
          {
            id: 'my account',
            title: 'My Account',
            type: 'item',
            url: '/user/myaccount',

          },
          {
            id: 'passwordEQ',
            title: 'Password Request',
            type: 'item',
            url: '/admin/password-reset-request'
          },
        ]
      }
    ]
  },
  /**/
  /**/
  {
    id: 'Course ',
    title: 'Course Details',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {id: 'Module',
      title: 'Create Module',
      type: 'item',
      icon: 'feather icon-layers',
      url: '/admin/module'

      },
      {
        id: 'Course',
        title: 'Create Course',
        type: 'item',
        icon: 'feather icon-book',
        url: '/admin/admincoursemodule'
      },
      {
        id: 'assignCourse',
        title: 'Assign Course',
        type: 'item',
        icon: 'feather icon-check-square',
        url: '/admin/assign-course'
      },

      {
        id: 'Subject',
        title: 'Create Subject',
        type: 'item',
        icon: 'feather icon-book',
        url: '/admin/subject'
      },
      {
        id: 'Unit',
        title: 'Create Unit',
        type: 'item',
        icon: 'feather icon-book',
        url: '/admin/unit'
      },
      {
        id: 'CourseList',
        title: 'Course Content',
        type: 'item',

        icon: 'feather icon-book',
        url: '/admin/admin-course-list'
      },
    ]},
    {
      id: 'Question Bank',
      title: 'Create QB',
      type: 'group',
      //icon: 'feather icon-clipboard',
     // url: '/admin/createQuestion',
      children: [
        {
          id: 'QB Practice',
          title: 'Create QB',
          type: 'item',
          icon: 'feather icon-clipboard',
          url: '/admin/createQuestion',
        },

        {
          id: 'UploadQuestion',
           title: 'Upload Questions',
           type: 'item',
           icon: 'feather icon-upload',
           url: '/admin/upload-question'
        },
        {
          id: 'question list',
          title: 'Question list',
          type: 'item',
          icon: 'feather icon-clipboard',
          url: '/admin/view-question-bank',
        },
        {
          id: 'createexam',
          title: 'Create Exam',
          type: 'item',
          icon: 'feather icon-clipboard',
          url: '/admin/create-exam',
        },

    ]
  },
    {
      id: 'Databases ',
      title: 'Databases',
      type: 'group',
      icon: 'feather icon-monitor',
      children: [
      {
        id: 'Study Material',
        title: 'Studying materials',
        type: 'item',
        icon: 'feather icon-layers',
        url: '/admin/studymaterial'
      },


      // {
      //   id: 'UploadQuestion',
      //    title: 'Uploaded Questions',
      //    type: 'item',
      //    icon: 'feather icon-upload',
      //    url: '/admin/uploaded-questions'
      // },
      // {
      //   id: 'Sharing Experience',
      //   title: 'Sharing Experience',
      //   type: 'item',
      //   icon: 'feather icon-message-square',
      //   url:'/user/progress'
      // },


    ]
  },

  {
    id: 'Exams',
    title: 'Exams',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      // {
      //   id: 'Exam Details',
      //   title: 'CBT  Exam',
      //   type: 'collapse',
      //   icon: 'feather icon-activity',
      //   children: [

      //     {
      //       id: ' QB Exams',
      //       title: 'QB  Exams',
      //       type: 'item',
      //       url: '/admin/QB-exam-list'
      //     },
      //     {
      //       id: 'CBT Model Exams',
      //       title: 'Model  Exams',
      //       type: 'item',
      //       url: '/admin/Model-exam-list'
      //     },


      //     {
      //       id: 'VIHQA',
      //       title: 'VIHQA ',
      //       type: 'item',
      //       url: '/admin/VIHQA-exam-list'
      //     },
      //     // {
      //     //   id: 'VIHQA',
      //     //   title: 'VIHQA',
      //     //   type: 'item',
      //     //   url: '/hospital/hosp-doctor'
      //     // }
      //   ]
      // },
      {
        id: 'Exam Details',
        title: 'CBT  Exam',
        type: 'collapse',
        icon: 'feather icon-activity',
        children: [

          {
            id: ' QB Exams',
            title: 'QB Exams',
            type: 'item',
            url: '/admin/QB-CBT-exam-list'
          },
          {
            id: 'CBT Model Exams',
            title: 'Model Exams',
            type: 'item',
            url: '/admin/Model-CBT-exam-list'
          },


          {
            id: 'VIHQA',
            title: 'VIHQA',
            type: 'item',
            url: '/admin/VIHQA-CBT-exam-list'
          },

        ],
       },
       {
         id: 'Results',
        title: 'Result Review',
         type: 'collapse',
         icon: 'feather icon-folder',
          children: [
        //    {
        //      id: 'ResultList',
        //      title: 'Result Review',
        //      type: 'item',
        //      url: '/admin/result-list'
        // },
        {
          id: ' QB Exams',
          title: 'QB Exams',
          type: 'item',
          url: '/admin/QB-result-list'
        },
        {
          id: 'CBT Model Exams',
          title: 'Model Exams',
          type: 'item',
          url: '/admin/Model-result-list'
        },


        {
          id: 'VIHQA',
          title: 'VIHQA  ',
          type: 'item',
          url: '/admin/VIHQA-result-list'
        },
         ]
       },

    ]
  },
    {
    id: 'pages',
    title: 'Pages',
    type: 'group',
    icon: 'feather icon-file-text',
    children: [
      {
        id: 'appointment',
      title: 'Training Appointment',
      type: 'collapse',
      icon: 'feather icon-monitor',
      children: [
        {
          id: 'training schedule',
        title: 'Training Schedule',
        type: 'item',

        url:'/admin/trainingschedule',
        },
        {
          id: 'approveAppointment',
          title: 'Appointment For Approve ',
          type: 'item',
          url:'/admin/Approve-Appointment',
        },
        {
          id: 'ApprovedAppointment',
          title: 'Approved Appointment',
          type: 'item',
          url:'/admin/Approved-Appointment',
        },
  ]
      },
      {
        id: 'Notification',
      title: 'Notification',
      type: 'collapse',
      icon: 'feather icon-monitor',
      children: [
      {
      id: 'compose',
      title: 'Compose',
      type: 'item',

      url:'/admin/notification',
      },
      {
      id: 'Read ',
      title: 'Read',
      type: 'item',

      url:'/user/Notification/show-All-Notification',
      },
      {
      id: 'All-Notification ',
      title: 'Notification List',
      type: 'item',

      url:'/admin/All-Notification',
      },
    ]},
      {
        id: 'Feedback',
      title: 'User Feedback',
      type: 'item',
      icon: 'feather icon-message-circle',
      url:'/admin/Feedback-list',
      },
      {
        id: 'SharingExperience',
      title: 'Sharing Experience',
      type: 'collapse',
      icon: 'feather icon-monitor',
      children: [
      {
        id: 'experienceSharing',
      title: 'Compose',
      type: 'item',

      url:'/admin/user-experience',
      },
      {
        id: 'Approveexperience',
      title: 'Approve experience',
      type: 'item',
      url:'/admin/approve-experience',
      },
      {
        id: 'experience',
      title: 'Candidate / User ',
      type: 'item',

      url:'/admin/experience-list',
      },
    ]},
    {
      id: 'Advertisement',
    title: 'Advertisement',
    type: 'collapse',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'CreateAdvertisement',
        title: 'Create ',
        type: 'item',

        url:'/admin/Advertisement'

      },
      {
        id: 'viewAdvertisement',
        title: 'View',
        type: 'item',

        url:'/admin/View-Advertisement'

      },
    ]
  },
  // {
  //   id: 'Studymaterials',
  //    title: 'Chat',
  //   type: 'item',
  //   icon: 'feather icon-message-circle',

  // url:'/user/chat',
  // },
    ]
  },
  {
    id: 'Logout',
    title: 'Logout',
    type: 'item',
    icon: 'feather icon-log-out',
    url:''
    },

    {

      id: 'Helpdesk',
      title: 'Helpdesk',
      type: 'group',
      icon: 'feather icon-folder',
      children: [
        {
          id: 'Contact Us',
          title: 'Contact Us',
          type: 'item',
          icon: 'feather icon-phone',
          url:'/user/contact-us'
          },
    ]
    },
  {
    id: 'Logout',
    title: 'Logout',
    type: 'item',
    icon: 'feather icon-log-out',
    url:'/auth/logout'
    },

]

@Injectable()
export class NavigationItem {
  public get() {
    return NavigationItems;
  }
}
