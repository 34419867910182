import { questionModel } from './../../../../models/question.model';
import { from } from 'rxjs';
import { Component, OnInit, ViewChild } from '@angular/core';

import examQuestionData from './examQuestionData.json';
import { Router, ActivatedRoute , ParamMap,Params} from '@angular/router';
import { AuthenticationService } from 'src/app/services/Auth-Service/AuthenticationService.service';
//import {que} from '../../../../models/question.model';
import Swal from 'sweetalert2/dist/sweetalert2.js';

import answerData from '../view-result/answerData.json';
import { UserService } from 'src/app/services/user/user-service.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
@Component({
  selector: 'app-start-exam',
  templateUrl: './start-exam.component.html',
  styleUrls: ['./start-exam.component.scss']
})
export class StartExamComponent implements OnInit {

  @ViewChild('modalConfirmation') modalConfirmation: any;

 que:questionModel=new questionModel();
    time: number = 60;
  display ;
 // interval;
  timeLeft: number =8100;
  interval;
  HD:any
  MD:any
  SD:any
  currentindex = 0;
 selectedoption = null;


  questionArray:any;
 questionList:any[]=[];
 // public questionArray = examQuestionData;
   //public questionList = examQuestionData.Question;
  //  public examList = answerData.Question;

  // private sub: any;
  examId: number;
 //id: any;
 //save data
  totalScore = 0;
  scoredMarks = 0;
  NotAttempt=0;
  correct=0;
  inCorrect=0;
  attemptOn=0;


  //// confirmation data
  NotAttemptCount = 0;
  AttemptCount = 0;
  ReviewLaterCount=0;
  UnAnsweredCount=0;
  constructor(  private router: Router,
    private route: ActivatedRoute,private authService:AuthenticationService,private service:UserService) {

    }
   ngOnInit(): void {
    this.route.params.subscribe(params => {
    //  this.examId=params.id!=undefined?params.id:0;
      //console.log(params)
      //this.getExamsById(this.examId);
    })
    this.authService.ExamId.subscribe(data => {
      if (data) {
        //////console.log(data,'data');
        this.examId=data;
      //  con
      }
    });

    this.getUserExams(this.examId);

     this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
         this.secondsToHms(this.timeLeft)
      } else {
      // this.timeLeft = 60;
         Swal.fire('', 'Time Over !', 'error');
        this.submtExam();
      }
    },1000

    );


  }

  secondsToHms(d) {
 // debugger ;
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);
    var hDisplay = h > 0 ? h + (h == 1 ? "  " : ": ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? "  " : " : ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " " : " ") : "";

    this.HD=hDisplay;
    this.MD=mDisplay;
    this.SD=sDisplay;
  // //console.log(hDisplay + mDisplay + sDisplay,'v')
    return hDisplay + mDisplay + sDisplay;

 }
  getUserExams(examId) {

    this.service.getExamsById(examId).subscribe(result => {
     let respo = <any>result;
     this.questionArray = respo.data;
     this.questionList = respo.data.question;
     this.timeLeft = respo.data.duration*60;

    if( this.questionList[0].NotVisited==true){
    this.initalQuestionCheck ()
  }
    })
  }
  initalQuestionCheck (){

        if(this.selectedoption!=null){
          this.questionList[this.currentindex].Answered=true;
        }
        else{
          this.questionList[this.currentindex].UnAnswered=true;
          this.questionList[this.currentindex].NotVisited=false;
        }

  }
  pauseTimer() {
       Swal.fire({
      title: 'Are you sure?',
      text: 'Once pause exam, you will not be able to  continue the exam!',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true
    }).then((willDelete) => {
        if (willDelete.dismiss) {
          Swal.fire('', 'You can continue your exam !', '');
        } else {
          Swal.fire('', ' Exam paused successfully ', 'success');
           this.router.navigate(['user/studenthome']);
            clearInterval(this.interval);
        }
      });

  }
  onSelect(selectedOption:any) {
  //debugger;
    if(this.selectedoption!=null){
     if( this.questionList[this.currentindex].Answered==false){
      this.questionList[this.currentindex].Answered=true;
      this.questionList[this.currentindex].NotVisited=false;
      this.questionList[this.currentindex].UnAnswered=false;

     }
      if(this.questionList[this.currentindex].ReviewLater==true){
       this.questionList[this.currentindex].ReviewLater=false;
       //   this.questionList[this.currentindex].Answered=true;
    }
    }
    else{
      this.questionList[this.currentindex].UnAnswered=true;
      this.questionList[this.currentindex].NotVisited=false;
    }
     this.questionList[this.currentindex].chosenAnswer=selectedOption;
  }
 onNextClick() {
  //debugger;

   this.initalQuestionCheck ();
   // //////console.log('currentindex',this.questionList)
    this.selectedoption=this.questionList[this.currentindex].chosenAnswer;
    this.selectedoption = null;
    this.currentindex++;
  }

 onSaveNextClick() {
  //debugger;
  if(this.selectedoption!=null){
   // this.questionList[this.currentindex].UnAnswered=false;
   this.questionList[this.currentindex].Answered=true;
   this.questionList[this.currentindex].NotVisited=false;
   this.questionList[this.currentindex].ReviewLater=false;
    //////console.log('currentindex',this.questionList)
    this.selectedoption=this.questionList[this.currentindex].chosenAnswer;
    this.selectedoption = null;
    this.currentindex++;
    // if(this.questionList[this.currentindex].chosenAnswer==this.questionList[this.currentindex].CorrectOption){
    //   this.questionList[this.currentindex].ifCorrect=true;
    // }
  //  //////console.log(this.questionList,'answer h')
  }
  else{
  Swal.fire('Select Your Answer !', '', 'warning');
    // this.questionList[this.currentindex].UnAnswered=true;
    // this.questionList[this.currentindex].NotVisited=false;
    // this.questionList[this.currentindex].ReviewLater=false;
  }

  }
  SaveEaxm(){
    if(this.selectedoption!=null){
      // this.questionList[this.currentindex].UnAnswered=false;
      this.questionList[this.currentindex].Answered=true;
      this.questionList[this.currentindex].NotVisited=false;
      this.questionList[this.currentindex].ReviewLater=false;
       //////console.log('currentindex',this.questionList)
       this.selectedoption=this.questionList[this.currentindex].chosenAnswer;
     //  this.selectedoption = null;


     }
     else{
     Swal.fire('Select Your Answer !', '', 'warning');
     }
  }
  onQuestion(i:any){

  //  //////console.log(i,'index');

    this.currentindex= i;
    //////console.log('selectedObj',this.currentindex);

  this.selectedoption=this.questionList[this.currentindex].chosenAnswer;
  if(this.selectedoption!=null){

   this.questionList[this.currentindex].Answered=true;
   this.questionList[this.currentindex].NotVisited=false;

    if(this.questionList[this.currentindex].ReviewLater==true){
      this.questionList[this.currentindex].ReviewLater=true;
      this.questionList[this.currentindex].Answered=false;
       this.questionList[this.currentindex].UnAnswered=false;
    }
  }
  else{
    this.questionList[this.currentindex].UnAnswered=true;
    this.questionList[this.currentindex].NotVisited=false;
  }
  // //////console.log('currentindex',this.questionList)
  }
  reviewLater(){
//debugger;
    //////console.log('currentindex',this.questionList)
    if(this.questionList[this.currentindex].ReviewLater==false){
      this.questionList[this.currentindex].ReviewLater=true;
      this.questionList[this.currentindex].Answered=false;
       this.questionList[this.currentindex].UnAnswered=false;

    }



   if(this.currentindex < this.questionList.length - 1){
    this.currentindex++;
    this.selectedoption = null;
   }

  this.selectedoption=this.questionList[this.currentindex].chosenAnswer;

}

 onClear(){
//  debugger;
  ////////console.log(this.questionList[this.currentindex],'li');

  if(this.selectedoption!=null){
    this.questionList[this.currentindex].Answered=false;
   // this.questionList[this.currentindex].UnAnswered=true;
    this.questionList[this.currentindex].NotVisited=false;
    this.questionList[this.currentindex].chosenAnswer=null;
     this.questionList[this.currentindex].UnAnswered=true;
    this.selectedoption = null;
  }

 }
 onBack(){
  ////////console.log(this.currentindex);
  ///debugger;
   if(this.selectedoption!=null){
   // this.questionList[this.currentindex].UnAnswered=false;
   this.questionList[this.currentindex].Answered=true;
   this.questionList[this.currentindex].NotVisited=false;
   //this.questionList[this.currentindex].ReviewLater=false
  }
  if(this.questionList[this.currentindex].ReviewLater==true){
      this.questionList[this.currentindex].ReviewLater=true;
      this.questionList[this.currentindex].Answered=false;
       this.questionList[this.currentindex].UnAnswered=false;
    }
  else{
    this.questionList[this.currentindex].UnAnswered=true;
    this.questionList[this.currentindex].NotVisited=false;
  }
   this.currentindex--;
  this.selectedoption=this.questionList[this.currentindex].chosenAnswer;
}
  getBackgroundColor(i:any){
 // debugger;
   // //////console.log(i,'i')
    if(this.questionList[i].Answered==true){
      return  '#449d44';
    }
    else if(this.questionList[i].UnAnswered==true){
      return '#c9302c';
    }
    if(this.questionList[i].ReviewLater==true){
      return '#f6c23e';
    }
  }
  submtExam(){
    //console.log('jhiii')
    var t= (this.questionArray.duration*60)-this.timeLeft;
   clearInterval(this.interval);
   var today = new Date();

      for(let question of this.questionList){

        if(question.CorrectOption==question.chosenAnswer){

         this.totalScore = this.totalScore+this.questionArray.correctScore;
         this.correct += 1;
         question.ifCorrect=true
        }
        else{
          this.inCorrect +=1;
        }
        if(question.NotVisited==true){
         this.NotAttempt +=1
        }
        else{

        }
      }
      this.questionArray.totalMark =this.questionArray.totalMark * this.questionArray.correctScore;
      this.questionArray.totalScore =this.totalScore;
      this.questionArray.Percentage =(this.totalScore*100)/this.questionArray.totalMark;
      if(this.questionArray.Percentage >=this.questionArray.minimumMarks){
        status="Pass"
      }
      else{
        status="Fail"
      }
    // let resultData=this.questionList

    //
    let examResultData={
      exam:this.questionArray,
      user:localStorage.getItem("userId"),
      NotAttemptt:this.NotAttempt,
      attemptOn:today,
      correct:this.correct,
      inCorrect:this.inCorrect,
      status:status,
      timeSpent:t
    }
    //console.log(examResultData,'result')

    this.service.saveExamResults(examResultData).subscribe(result => {
      let respo = <any>result;
      let id=respo.data.id
      Swal.fire('', 'Exam Completed !', 'success');
         ////console.log('result Id ',id)
        // this.router.navigateByUrl("/start-exam/"+id);
          this.router.navigateByUrl('admin/view-result/'+id);
          //this.router.navigateByUrl('admin/view-result');
  })
  }

  ModalConfirOP(){
  //console.log(this.questionList)
    for(let question of this.questionList){


      if(question.NotVisited==true){
       this.NotAttemptCount +=1
      }

      if(question.Answered==true){
       this.AttemptCount +=1
      }
      if(question.ReviewLater==true){
       this.ReviewLaterCount +=1
      }
      if(question.UnAnswered==true){
       this.UnAnsweredCount +=1
      }

    }
    this.modalConfirmation.show();

  }
  clearModalCnf(){
    this.NotAttemptCount=0;
    this.AttemptCount=0;
    this.ReviewLaterCount=0;
    this.UnAnsweredCount=0;
  }
}

