import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest,HttpErrorResponse, HttpResponse, HttpHeaderResponse, HttpProgressEvent, HttpSentEvent, HttpUserEvent
} from '@angular/common/http';

import { Observable} from 'rxjs';
import{tap} from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService } from '../Auth-Service/AuthenticationService.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

 // private token = localStorage.getItem("token");
  constructor(private router: Router) {}

   intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
//debugger;
  const token= localStorage.getItem("token");
      const newReq= req.clone({url:""+req.url,
      headers: req.headers.set("Authorization",`Bearer ${token}`) })
    return next.handle(newReq)
    .pipe(
       tap(()=>{},
       (err: any) => {
         if (err instanceof HttpErrorResponse) {
           if (err.status !== 401) {

            return;

           }
           this.router.navigate(['auth/signin']);

         }
       }));

   }

}
