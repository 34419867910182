import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { EndpointService } from 'src/app/services/EndPoint/endpoint.service';
import { UserService } from 'src/app/services/user/user-service.service';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-show-all-notification',
  templateUrl: './show-all-notification.component.html',
  styleUrls: ['./show-all-notification.component.scss']
})
export class ShowAllNotificationComponent implements OnInit {
   public isCollapsed: boolean;
  public isMail: string;
  public isSubMail: string;

  userList:any;
  notificationList: any;
 /// notificationId: any;
 pageSize = 20;
 page =1
 notificationCount:number;
  Uname = localStorage.getItem("firstName");
uId = localStorage.getItem("userId");
@ViewChild('notificationmodel') notificationmodel: any;
///notificationData:notificationModel= new notificationModel;
notificationData:any;
  constructor(  private router:Router,private service: UserService,private endpoint:EndpointService,)
{
    this.isCollapsed = false;
    this.isMail = 'inbox';
    this.isSubMail = 'primary';
  }

  ngOnInit(): void
  {
   // console.log('hi')
     this.getNotificationById(this.uId);
  }
    getNotificationById(uId) {
    this.service.getNotificationBYId(uId).subscribe((result) => {
      let respo = <any>result;
      this.notificationList=respo.data;
      //this.notificationId=respo.data.id;
      this.notificationCount=this.notificationList.length
      //console.log(this.notificationList, 'NotList');
      this.notificationList.reverse();
      //console.log(this.notificationId, ' Id');
    });
  }
  viewMessage(id,item){
    this.service.resadnotification(id).subscribe(result => {
      let respo = <any>result;
    //  console.log(respo,'hhh')
      Swal.fire({
      title: `${item.title}`,
      text: `${item.notification}`,
      type: 'success',
      showCloseButton: true,
      // showCancelButton: true


    });
    this.getNotificationById(this.uId);

  } );
  this.router.navigate(['user/Notification/show-All-Notification']);
  }
  close(){
 this.notificationmodel.hide()
 this.notificationData=null;
  }

}
