import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { cbtExamEditPreviewModel } from 'src/app/models/examPreview-edit.model';
import { AuthenticationService } from 'src/app/services/Auth-Service/AuthenticationService.service';
import { EndpointService } from 'src/app/services/EndPoint/endpoint.service';
import { UserService } from 'src/app/services/user/user-service.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Location } from '@angular/common'
import {  NavigationEnd } from '@angular/router'

@Component({
  selector: 'app-cbtexam-preview',
  templateUrl: './cbtexam-preview.component.html',
  styleUrls: ['./cbtexam-preview.component.scss']
})
export class CBTExamPreviewComponent implements OnInit {
  questionArray:any;
questionList:any[]=[];
currentindex = 0;
Id:any;
editQuestionView:boolean=false;
previewData:cbtExamEditPreviewModel=new cbtExamEditPreviewModel();
examName;
imgUrl1:any;
urlpath = this.endpoint.backendUrl;
private history: string[] = []
  questionId: any;
constructor(  private router: Router,private endpoint:EndpointService,private sanitized: DomSanitizer,
  private route: ActivatedRoute,private authService:AuthenticationService,private service:UserService,
  private location: Location) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects)
      }
    })
  }

ngOnInit(): void {
    this.authService.ExampreviewId.subscribe(data => {
    if (data) {
      ////console.log(data,'data');
      this.Id=data;
    //  con
    }
  });
  this.getUserExams(this.Id)

}
getUserExams(examId) {
//debugger;
  this.service.getQBExam(examId).subscribe(result => {
   let respo = <any>result;
   this.questionArray = respo.data;
   this.questionList = respo.data.question;
   this.examName=this.questionArray.examName

    if(this.currentindex==0){
      this.questionList[this.currentindex].NotVisited=false
    }

  })
}
initalQuestionCheck (){
  this.questionList[0].NotVisited==false
 // console.log(this.questionList[0],'exam')
}
 onNextClick() {
  this.currentindex++;
  this.questionList[this.currentindex].NotVisited=false;
}
 onQuestion(i) {
  this.currentindex= i;
  this.questionList[this.currentindex].NotVisited=false;
}
 onBack(){
   this.currentindex--;
 }
 onBackList(): void {
  this.location.back();
  this.questionArray=null;
  this.questionList=[];
  // this.history.pop()
  // if (this.history.length > 0) {
  //   this.location.back()
  // } else {
  //   this.router.navigateByUrl('/')
  // }
}/*
 onBackList(){
this.router.navigate(['admin/adminhome']);
}*/
editOpen(q){
  //this.previewData=null;
  //console.log(q)
  this.editQuestionView=true
  this.questionId=q.id
  this.previewData=q
// this.previewData.Question=q.Question.replace(/<\/?[^>]+>/gi, '');
// this.previewData.Option1=q.Option1.replace(/<\/?[^>]+>/gi, '');
// this.previewData.Option2=q.Option2.replace(/<\/?[^>]+>/gi, '');
// this.previewData.Option3=q.Option3.replace(/<\/?[^>]+>/gi, '');
// this.previewData.Option4=q.Option4.replace(/<\/?[^>]+>/gi, '');
// this.previewData.Option5=q.Option5.replace(/<\/?[^>]+>/gi, '');
// this.previewData.CorrectOption=q.CorrectOption.replace(/<\/?[^>]+>/gi, '');
// this.previewData.Description=q.Description.replace(/<\/?[^>]+>/gi, '');
// this.previewData.answer=q.answer.replace(/<\/?[^>]+>/gi, '');
this.previewData.id=q.id

 //console.log(this.previewData,'p')
}


save(){
// console.log('data',this.questionId)
// console.log('data',this.previewData)
  Swal.fire({
    title: 'Are you sure?',
    text: 'Are you sure to Edit!',
    type: 'warning',
    showCloseButton: true,
    showCancelButton: true
  }).then((willDelete) => {
      if (willDelete.dismiss) {
        Swal.fire('', 'canceld', 'error');
      } else {
        this.service.editQuestionBank(this.previewData).subscribe(result => {
          this.previewData=new cbtExamEditPreviewModel();
        //  this.getAllAssignCourses();
          Swal.fire('Question edit successfully completed. ', '  ', 'success');
          this.editQuestionView=false;
          this.getUserExams(this.Id)
        })

      }
    });

}
cancel(){
  this.editQuestionView=false
}
getBackgroundColor(i:any){
  // debugger;
    // // //console.log(i,'i')

     if(this.questionList[i].NotVisited==false){

       return  '#c9302c';
     }
     else{
       return '#000000';
     }

   }
}
