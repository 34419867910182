import {Injectable} from '@angular/core';

export interface MentorNavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: MNavigation[];
}

export interface MNavigation extends MentorNavigationItem {
  children?: MentorNavigationItem[];
}

const MentorNavigationItems = [
    /**/
    {
      id: 'Home',
      title: 'Home',
      type: 'group',
      // icon: 'feather icon-align-left',
      children: [
        {
          id: 'adminhome',
          title: 'Dashboard',
          type: 'item',
          icon: 'feather icon-monitor',
          url: '/mentor/mentor-home',

        },
        {
          id: 'my account',
          title: 'My Account',
          type: 'item',
          icon: 'feather icon-user',
          url: '/user/myaccount',

        },

      ]
    },
        {
          id: 'Profile',
          title: ' Profile',
          type: 'group',
          icon: 'feather icon-home',
          children: [

            {
              id: 'user List',
              title: 'Student List',
              type: 'item',
              icon: 'feather icon-users',
              url: '/mentor/student',
            },
            // {
            //   id: 'approveUser ',
            //   title: 'Approve Student',
            //   type: 'item',
            //   icon: 'feather icon-user-check',
            //   url: '/mentor/approve-user',
            // }
            // ,
            // {
            //   id: 'assignCourse',
            //   title: 'Assign Course',
            //   type: 'item',
            //   icon: 'feather icon-check-square',
            //   url: '/mentor/assign-course'
            // },
            // {
            //   id: 'passwordEQ',
            //   title: 'Password Request',
            //   type: 'item',
            //   icon: 'feather icon-list',
            //   url: '/mentor/password-reset-request'
            // },

      ]
    },
    /**/
    /**/
    {
      id: 'Course ',
      title: 'Course Details',
      type: 'group',
      icon: 'feather icon-monitor',
      children: [

        {
          id: 'Subject',
          title: 'Create Subject',
          type: 'item',
          icon: 'feather icon-edit',
          url: '/mentor/subject'
        },
        {
          id: 'Unit',
          title: 'Create Unit',
          type: 'item',
          icon: 'feather icon-edit-1',
          url: '/mentor/unit'
        },
        {

              id: 'Study Material',
              title: 'Upload Materials',
              type: 'item',
              icon: 'feather icon-upload',
              url: '/mentor/material'
            },

        {
          id: 'CourseList',
          title: 'Course Content',
          type: 'item',

          icon: 'feather icon-book',
          url: '/mentor/subject-list'
        },
      ]},


    // {
    //   id: 'Exams',
    //   title: 'MCQ Exams',
    //   type: 'group',
    //   icon: 'feather icon-monitor',
    //   children: [

    //     // {
    //     //   id: 'UploadQuestion',
    //     //    title: 'Upload Question List',
    //     //    type: 'item',
    //     //    icon: 'feather icon-upload',
    //     //    url: '/mentor/MCQ-question-List'
    //     // },

    //     {
    //       id: 'Exam Details',
    //       title: 'Exam Details',
    //       type: 'collapse',
    //       icon: 'feather icon-clipboard',
    //       children: [

    //             {
    //               id: 'DemoM',
    //               title: 'Model',
    //               type: 'item',
    //               url: '/mentor/mentor-Model-exam-list'
    //             },
    //             {
    //               id: ' QB ExamsM',
    //               title: 'QB',
    //               type: 'item',
    //               url: '/mentor/mentor-QB-exam-list'
    //             },

    //             {
    //               id: 'VIHQA',
    //               title: 'VIHQA',
    //               type: 'item',
    //               url: '/mentor/mentor-VIHQA-exam-list'
    //             },
    //           ]

    //     },

    //   ]
    // },

        //  {
        //    id: 'Results',
        //   title: 'Result Review',
        //    type: 'collapse',
        //    icon: 'feather icon-percent',
        //     children: [
        //   //    {
        //   //      id: 'ResultList',
        //   //      title: 'Result Review',
        //   //      type: 'item',
        //   //      url: '/mentor/result-list'
        //   // },
        //   {
        //     id: 'CBT Model Exams',
        //     title: 'Model',
        //     type: 'item',
        //     url: '/mentor/mentor-demo-result-list'
        //   },

        //   {
        //     id: ' QB Exams',
        //     title: 'QB',
        //     type: 'item',
        //     url: '/mentor/mentor-Live-result-list'
        //   },


        //  {
        //     id: 'VIHQA',
        //     title: 'VIHQA',
        //     type: 'item',
        //     url: '/mentor/VIHQA-result-list'
        //   },
        //    ]
        //  },


    {
      id: 'Question Bank',
      title: 'Create QB',
      type: 'group',
      //icon: 'feather icon-clipboard',
     // url: '/mentor/createQuestion',
      children: [
        {
          id: 'QB Practice',
          title: 'Create QB',
          type: 'item',
          icon: 'feather icon-help-circle',
          url: '/mentor/createQuestion-Mentor',
        },
        {
          id: 'UploadQuestion',
           title: 'Upload Question',
           type: 'item',
           icon: 'feather icon-upload',
           url: '/mentor/upload-MCQ'
        },
        {
          id: 'question list',
          title: 'Question list',
          type: 'item',
          icon: 'feather icon-list',
          url: '/mentor/Mentor-view-questionBank',
        },

        {
          id: 'createexam',
          title: 'Create QB Exam',
          type: 'item',
          icon: 'feather icon-clipboard',
          url: '/mentor/mentor-createExam',
        },


       /* {
          id: 'createexam',
          title: 'Create Exam',
          type: 'item',
          icon: 'feather icon-clipboard',
          url: '/mentor/create-exam',
        },*/

      ],
    },
    {
      id: 'Exam Details',
      title: 'QB Exam Details',
      type: 'collapse',
      icon: 'feather icon-clipboard',
      children: [

        {
          id: ' QB Exams',
          title: 'QB',
          type: 'item',
          url: '/mentor/QB-Mentor-ExamList'
        },
        {
          id: 'CBT Model Exams',
          title: 'Model',
          type: 'item',
          url: '/mentor/QBModel-Mentor-ExamList'
        },


        {
          id: 'VIHQA',
          title: 'VIHQA',
          type: 'item',
          url: '/mentor/QBVIHQA-Mentor-ExamList'
        },
        // {
        //   id: 'VIHQA',
        //   title: 'VIHQA',
        //   type: 'item',
        //   url: '/hospital/hosp-doctor'
        // }
      ]
    },

    {
      id: 'Results',
     title: 'Result Review',
      type: 'collapse',
      icon: 'feather icon-percent',
       children: [
     //    {
     //      id: 'ResultList',
     //      title: 'Result Review',
     //      type: 'item',
     //      url: '/mentor/result-list'
     // },


     {
       id: ' QBExamsR',
       title: 'QB ',
       type: 'item',
       url: '/mentor/mentor-Live-result-list'
     },
     {
      id: 'CBTModelExamsR',
      title: 'Model ',
      type: 'item',
      url: '/mentor/mentor-Demo-result-list'
    },

    {
       id: 'VIHQAR',
       title: 'VIHQA',
       type: 'item',
       url: '/mentor/mentor-VIHQA-exam-list'
     },
      ]
    },
    /*{
      id: 'Databases ',
      title: 'Databases',
      type: 'group',
      icon: 'feather icon-monitor',
      children: [


      {
        id: 'UploadQuestion',
         title: 'Upload Questions',
         type: 'item',
         icon: 'feather icon-upload',
         url: '/mentor/upload-question'
      },
      {
        id: 'Sharing Experience',
        title: 'Sharing Experience',
        type: 'item',
        icon: 'feather icon-message-square',
        url: '/user/studenthome'
      },
      {
        id: 'Advertisement',
        title: 'Advertisement',
        type: 'item',
        icon: 'feather icon-tv',
        url:'/user/progress'
       url: '/user/studenthome'
      },

    ]
  },
      {
      id: 'pages',
      title: 'Pages',
      type: 'group',
      icon: 'feather icon-file-text',
      children: [
        {
          id: 'auth',
          title: 'Authentication',
          type: 'collapse',
          icon: 'feather icon-lock',
          children: [
            {
              id: 'signin',
              title: 'Sign In',
              type: 'item',
              url: '/auth/signin',
              target: true,
              breadcrumbs: false
            },
          ]
        },*/
        {
        id: 'notification',
        title: 'Notifications',
        type: 'group',
        icon: 'feather icon-bell',

         children:
        [

          {
            id: 'Read Notifications',
            title: 'Compose',
            type: 'item',
            icon: 'feather icon-mail',
            url:'/admin/notification',
            },
          {
          id: 'Read Notifications',
          title: 'Read',
          type: 'item',
          icon: 'feather icon-message-square',
          url:'/user/Notification/show-All-Notification',
          },
        /*{
          id: 'training schedule',
        title: 'Training Schedule',
        type: 'item',
        icon: 'feather icon-clock',
        url:'/mentor/trainingschedule',
        }*/
      ]
    },

    // {
    //   id: 'Feedback',
    // title: 'User Feedback',
    // type: 'item',
    // icon: 'feather icon-message-circle',
    // url:'/mentor/Feedback-list',
    // },
    {
      id: 'Helpdesk',
      title: 'Helpdesk',
      type: 'group',
      icon: 'feather icon-monitor',
      children: [

        {
          id: 'Contact Us',
          title: 'Contact Us',
          type: 'item',
          icon: 'feather icon-phone',
          url:'/user/contact-us'
          },
      ]
    },
    {
      id: 'Logout',
      title: 'Logout',
      type: 'item',
      icon: 'feather icon-log-out',
      url:'/auth/logout'
      },
]

@Injectable()
export class MentorNavigationItem {
  public get() {
    return MentorNavigationItems;
  }

}
