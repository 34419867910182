<div class="card">
  <div class="header-custom">
    <div class="row">
      <div class=" col-sm-6">
        <h1 class="">{{ questionArray&& questionArray.examName}}</h1>

      </div>
      <div class="form-group col-sm-6" align="end">
      <h1>TimeLeft..... 0{{HD+MD+SD }}</h1>
      </div>
    </div>
  </div>
  <div class="row examBody">
    <div class="form-group col-sm-9">
      <div class="card-body">
        <div
          class=""
          *ngIf="currentindex <= questionList.length - 1; let i = index"
        >
          <label class="form-group col-6"
            >Question {{  currentindex +1  }})</label
          >
          <label class="form-group col-6">Mark(s): {{questionArray&& questionArray.correctScore}}</label>
        </div>

        <fieldset class="row">
          <div class="m-l-10 col-sm-12">
            <label class="form-group"
              >{{ questionList&& questionList[currentindex].question.Question }}
               ?</label
            >
            <span  class="form-row" *ngIf="questionList[currentindex].question.filePath!=null">
              <img  [src]="urlpath+questionList[currentindex].question.filePath" class=" form-row prf_img">

            </span>

                <div class="form-group col-sm-9 m-l-10 ">
              <div class="form-check ">
                <input
                  class="form-check-input"
                  type="radio"
                  name="gridRadios"
                  [(ngModel)]="selectedoption"
                  id="gridRadios1"
                 [value]="1"
                  (change)="onSelect(selectedoption)"
                />
                <label class="form-check-label" for="gridRadios1"
                  >A)  {{ questionList[currentindex].question.Option1 }}

                  </label
                >
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="gridRadios"
                  [(ngModel)]="selectedoption"
                  id="gridRadios2"
                  [value]="2"
                  (change)="onSelect(selectedoption)"
                />
                <label class="form-check-label" for="gridRadios2">
                  B)  {{ questionList[currentindex].question.Option2 }}.</label
                >
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="gridRadios"
                  [(ngModel)]="selectedoption"
                  id="gridRadios3"
                  [value]="3"
                  (change)="onSelect(selectedoption)"
                />
                <label class="form-check-label" for="gridRadios3"
                  >C)  {{ questionList[currentindex].question.Option3 }}.</label
                >
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="gridRadios"
                  [(ngModel)]="selectedoption"
                  id="gridRadios4"
                  [value]="4"
                  (change)="onSelect(selectedoption)"
                />
                <label class="form-check-label" for="gridRadios4"
                  >D)  {{ questionList[currentindex].question.Option4 }}.</label
                >
              </div>
              <div class="form-check" *ngIf="questionList[currentindex].question.Option5 '!=null'">
                <input
                  class="form-check-input"
                  type="radio"
                  name="gridRadios"
                  [(ngModel)]="selectedoption"
                  id="gridRadios5"
                  [value]="5"
                  (change)="onSelect(selectedoption)"
                />
                <label class="form-check-label" for="gridRadios5"
                  >E)  {{ questionList[currentindex].question.Option5 }} .</label
                >
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
    <div class="col-sm-3 custom-bag-exam">
      <div style="text-align: center"></div>
      <div class="">
        <div class="btn-toolbar">
          <div
            class="nextBtn "
            *ngFor="let item of questionList; let i = index"
          >


          <button
          class=
          "btn"
          type="button"
           [style.background-color]="getBackgroundColor(i)"

              (click)="onQuestion(i)"

              >
              {{ i + 1 }}

            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="examFooter">
    <div class="row">
      <div class="col-md-7">
        <div
          class="btn-group"
          *ngIf="currentindex <= questionList.length - 1; let i = index"
        >
          <button
            type="button"
            class="btn btn-sm review-btn"
          [disabled]="currentindex  > questionList.length - 1 "
            (click)="reviewLater()"
          >
            Review Later
          </button>
          <button type="button" class="btn btn-sm clearPause-btn" (click)="onClear()">clear</button>
          <button type="button"
          *ngIf="currentindex !== 0"
          class="btn btn-sm btn-primary" (click)="onBack()">Back</button>


          <button
            type="button"
            [disabled]="currentindex  >= questionList.length -1 "
            class="btn btn-sm nextSave-btn m-l-20"
            (click)="onNextClick()"
          >
            Next
          </button>
          <button
            type="button"
            class="btn btn-sm nextSave-btn"
           *ngIf="currentindex  < questionList.length - 1 "
            (click)="onSaveNextClick()"
          >
            Save & Next
          </button>
          <button
            type="button"
            class="btn btn-sm nextSave-btn"
           *ngIf="currentindex  == questionList.length - 1  "

           (click)="SaveEaxm()">
            Save
          </button>

<div class= "subbtn">
          <button type="button" class="btn btn-sm submytAns-btn" (click)="ModalConfirOP()">
            Submit Exam
          </button>

          </div>
        </div>
      </div>
      <div class="col-md-5" align="end">
        <div class="btn-group">
          <button type="button" class="btn btn-sm answeredQu-btn">
            Answered
          </button>
          <button type="button" class="btn btn-sm unAnsweredQu-btn">
            UnAnswered
          </button>
          <button type="button" class="btn btn-sm review-btn">
            Review Later(RL)
          </button>
          <button type="button" class="btn btn-sm NotVisited-btn">
            Not-visited
          </button>

        </div>
      </div>
    </div>
  </div>
</div>
  <app-ui-modal #modalConfirmation dialogClass="modal-dialog-centered">
    <div class="app-modal-header">
      <h2 class="modal-title">Are you sure Want Submit Your Exam </h2>
      <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="modalConfirmation.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
      <p>Total Question  = {{questionList.length}}</p>
      <p>No.Of Question Answered = {{AttemptCount}}</p>
      <p>No.Of Not visited  Question = {{NotAttemptCount}}</p>
      <p>No.Of Review Later Question = {{ReviewLaterCount}}</p>
      <p>No.Of UnAnswered Question = {{UnAnsweredCount}}</p>
    </div>
    <div class="app-modal-footer">
      <button type="button" class="btn btn-danger m-r-5" data-dismiss="modal" (click)="modalConfirmation.hide();clearModalCnf()">No</button>
      <button type="button" class="btn btn-primary" (click)="submtExam()" data-dismiss="modal" (click)="modalConfirmation.hide()">Submit</button>
    </div>
  </app-ui-modal>
