import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ShowAllNotificationComponent } from './lms/common-authentication/show-all-notification/show-all-notification.component';
import { AttemptExamDetailsComponent } from './lms/lmsadmin/examModule/attempt-exam-details/attempt-exam-details.component';
import { StartExamComponent } from './lms/lmsadmin/examModule/start-exam/start-exam.component';
//import { LoginComponent } from './lms/user/login/login.component';
import { AdminComponent } from './theme/layout/admin/admin.component';
import {AuthComponent} from './theme/layout/auth/auth.component';

import { PreviewExamComponent } from './lms/lmsadmin/examModule/preview-exam/preview-exam.component';
import { AttendExamComponent } from './lms/user/student-exam/attend-exam/attend-exam.component';
import { CBTExamPreviewComponent } from './lms/lmsadmin/examModule/cbtexam-preview/cbtexam-preview.component';
import { CBTQbExamComponent } from './lms/user/QB-practice/cbtqb-exam/cbtqb-exam.component';
const routes: Routes =
[
  {
    path: '',
    component: AuthComponent,
    children: [
        {
        path: '',
        redirectTo: 'auth/signin',
        pathMatch: 'full'
      },
      {
        path: 'auth',
        loadChildren: () => import('./lms/common-authentication/commonAuthentication.module').then(module => module.CommonAuthenticationModule)
      },
      // {
      //   path: 'maintenance',
      //   loadChildren: () => import('./demo/pages/maintenance/maintenance.module').then(module => module.MaintenanceModule)
      // }
    ]
  },
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: 'admin/adminhome',
        pathMatch: 'full'
      },

// SGS Modules
{
  path: 'user',
  loadChildren: () => import('./lms/user/user.module').then(module => module.UserModule),

},

// SGS Modules
{

  path: 'admin',
  loadChildren: () => import('./lms/lmsadmin/lmsadmin.module').then(module => module.LmsadminModule)
},
///mentor
{

  path: 'mentor',
  loadChildren: () => import('./lms/mentor/mentor.module').then(module => module.MentorModule)
},

 //{path: "userlogin",component:LoginComponent},

/* SGS Modules End */

    ]
  },
    {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () => import('./lms/common-authentication/commonAuthentication.module').then(module => module.CommonAuthenticationModule)
      },
      // {
      //   path: 'maintenance',
      //   loadChildren: () => import('./demo/pages/maintenance/maintenance.module').then(module => module.MaintenanceModule)
      // }
    ]
  },
    {
    path: '',
    component: StartExamComponent,
    children: [
      {
        path: 'start-exam/:id',
        loadChildren: () => import('./lms/lmsadmin/lmsadmin.module').then(module => module.LmsadminModule)
      },
    ]
  },
    {
    path: '',
    component: AttendExamComponent,
    children: [
      {
        path: 'attend-exam/:id',
        loadChildren: () => import('./lms/user/user.module').then(module => module.UserModule)
      },
    ]
  },
    {
    path: '',
    component: PreviewExamComponent,
    children: [
      {
        path: 'preview',
        loadChildren: () => import('./lms/lmsadmin/lmsadmin.module').then(module => module.LmsadminModule)
      },
    ]
  },
    {
    path: '',
    component: CBTExamPreviewComponent,
    children: [
      {
        path: 'preview-CBT',
        loadChildren: () => import('./lms/lmsadmin/lmsadmin.module').then(module => module.LmsadminModule)
      },
    ]
  },
    {
    path: '',
    component: CBTQbExamComponent,
    children: [
      {
        path: 'QuestionBank-Exam/:id',
        loadChildren: () => import('./lms/user/user.module').then(module => module.UserModule)
      },
    ]
  },
    {
    path: 'Notification',
    component: ShowAllNotificationComponent,
    children: [
      {
        path: 'read-notification',
        loadChildren: () => import('./lms/common-authentication/commonAuthentication.module').then(module => module.CommonAuthenticationModule)
      },
    ]
  },
 //    {path: "preview",component:AttemptExamDetailsComponent },{path: "start-exam",component:StartExamComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
