import {Component, Input, OnInit} from '@angular/core';
import {NavigationItem} from '../../../layout/admin/navigation/navigation';
import {StudentNavigationItem } from 'src/app/theme/layout/admin/navigation/student-navigation';
import { MentorNavigationItem } from 'src/app/theme/layout/admin/navigation/navigation-mentor';

import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  @Input() type: string;

  public navigation: any;
  public snavigation: any;
  public mentorNavigation: any;
  breadcrumbList: Array<any> = [];
  public navigationList: Array<any> = [];
  public snavigationList: Array<any> = [];
  public mentorNavigationList: Array<any> = [];
  constructor(private route: Router, public nav: NavigationItem,public snav: StudentNavigationItem,
     private titleService: Title,public mentornav:MentorNavigationItem) {
    this.navigation = this.nav.get();
    this.snavigation = this.snav.get();
    this.mentorNavigation = this.mentornav.get();
    this.type = 'theme2';
    this.setBreadcrumb();
  }

  ngOnInit() {
    let routerUrl: string;
    routerUrl = this.route.url;
    //console.log(routerUrl,'url')
    if (routerUrl && typeof routerUrl === 'string') {
      this.filterNavigation(routerUrl);
      this.filterSNavigation(routerUrl);
      this.filterMentorNavigation(routerUrl);

    }
  }

  setBreadcrumb() {
    let routerUrl: string;
    this.route.events.subscribe((router: any) => {
      routerUrl = router.urlAfterRedirects;

      if (routerUrl && typeof routerUrl === 'string') {
        this.breadcrumbList.length = 0;
        const activeLink = router.url;
        //console.log("activeLink",activeLink)
        this.filterNavigation(activeLink);
        this.filterSNavigation(activeLink);
        this.filterMentorNavigation(activeLink);
      }
    });
  }

  filterNavigation(activeLink) {
    let result = [];
    let title = 'Welcome';
    this.navigation.forEach((a) => {
      if (a.type === 'item' && 'url' in a && a.url === activeLink) {
        result = [
          {
            url: ('url' in a) ? a.url : false,
            title: a.title,
            breadcrumbs: ('breadcrumbs' in a) ? a.breadcrumbs : true,
            type: a.type
          }
        ];
        title = a.title;
      } else {
        if (a.type === 'group' && 'children' in a) {
          a.children.forEach((b) => {
            if (b.type === 'item' && 'url' in b && b.url === activeLink) {
              result = [
                {
                  url: ('url' in b) ? b.url : false,
                  title: b.title,
                  breadcrumbs: ('breadcrumbs' in b) ? b.breadcrumbs : true,
                  type: b.type
                }
              ];
              title = b.title;
            } else {
              if (b.type === 'collapse' && 'children' in b) {
                b.children.forEach((c) => {
                  if (c.type === 'item' && 'url' in c && c.url === activeLink) {
                    result = [
                      {
                        url: ('url' in b) ? b.url : false,
                        title: b.title,
                        breadcrumbs: ('breadcrumbs' in b) ? b.breadcrumbs : true,
                        type: b.type
                      },
                      {
                        url: ('url' in c) ? c.url : false,
                        title: c.title,
                        breadcrumbs: ('breadcrumbs' in c) ? c.breadcrumbs : true,
                        type: c.type
                      }
                    ];
                    title = c.title;
                  } else {
                    if (c.type === 'collapse' && 'children' in c) {
                      c.children.forEach((d) => {
                        if (d.type === 'item' && 'url' in d && d.url === activeLink) {
                          result = [
                            {
                              url: ('url' in b) ? b.url : false,
                              title: b.title,
                              breadcrumbs: ('breadcrumbs' in b) ? b.breadcrumbs : true,
                              type: b.type
                            },
                            {
                              url: ('url' in c) ? c.url : false,
                              title: c.title,
                              breadcrumbs: ('breadcrumbs' in c) ? c.breadcrumbs : true,
                              type: c.type
                            },
                            {
                              url: ('url' in d) ? d.url : false,
                              title: d.title,
                              breadcrumbs: ('breadcrumbs' in c) ? d.breadcrumbs : true,
                              type: d.type
                            }
                          ];
                          title = d.title;
                        }
                      });
                    }
                  }
                });
              }
            }
          });
        }
      }
    });
    this.navigationList = result;
    //console.log("navigation",this.navigationList)
    this.titleService.setTitle(title + ' | NDT Studies');
}
filterSNavigation(activeLink) {
  let result = [];
  let title = 'Welcome';
  this.snavigation.forEach((aa) => {
    if (aa.type === 'item' && 'url' in aa && aa.url === activeLink) {
      result = [
        {
          url: ('url' in aa) ? aa.url : false,
          title: aa.title,
          breadcrumbs: ('breadcrumbs' in aa) ? aa.breadcrumbs : true,
          type: aa.type
        }
      ];
      title = aa.title;
    } else {
      if (aa.type === 'group' && 'children' in aa) {
        aa.children.forEach((bb) => {
          if (bb.type === 'item' && 'url' in bb && bb.url === activeLink) {
            result = [
              {
                url: ('url' in bb) ? bb.url : false,
                title: bb.title,
                breadcrumbs: ('breadcrumbs' in bb) ? bb.breadcrumbs : true,
                type: bb.type
              }
            ];
            title = bb.title;
          } else {
            if (bb.type === 'collapse' && 'children' in bb) {
              bb.children.forEach((cc) => {
                if (cc.type === 'item' && 'url' in cc && cc.url === activeLink) {
                  result = [
                    {
                      url: ('url' in bb) ? bb.url : false,
                      title: bb.title,
                      breadcrumbs: ('breadcrumbs' in bb) ? bb.breadcrumbs : true,
                      type: bb.type
                    },
                    {
                      url: ('url' in cc) ? cc.url : false,
                      title: cc.title,
                      breadcrumbs: ('breadcrumbs' in cc) ? cc.breadcrumbs : true,
                      type: cc.type
                    }
                  ];
                  title = cc.title;
                } else {
                  if (cc.type === 'collapse' && 'children' in cc) {
                    cc.children.forEach((dd) => {
                      if (dd.type === 'item' && 'url' in dd && dd.url === activeLink) {
                        result = [
                          {
                            url: ('url' in bb) ? bb.url : false,
                            title: bb.title,
                            breadcrumbs: ('breadcrumbs' in bb) ? bb.breadcrumbs : true,
                            type: bb.type
                          },
                          {
                            url: ('url' in cc) ? cc.url : false,
                            title: cc.title,
                            breadcrumbs: ('breadcrumbs' in cc) ? cc.breadcrumbs : true,
                            type: cc.type
                          },
                          {
                            url: ('url' in dd) ? dd.url : false,
                            title: dd.title,
                            breadcrumbs: ('breadcrumbs' in cc) ? dd.breadcrumbs : true,
                            type: dd.type
                          }
                        ];
                        title = dd.title;
                      }
                    });
                  }
                }
              });
            }
          }
        });
      }
    }
  });
  this.snavigationList = result;
  //console.log("navigation",this.navigationList)
  this.titleService.setTitle(title + ' | NDT Studies ');
}
filterMentorNavigation(activeLink) {
  let result = [];
  let title = 'Welcome';
  this.mentorNavigation.forEach((aa) => {
    if (aa.type === 'item' && 'url' in aa && aa.url === activeLink) {
      result = [
        {
          url: ('url' in aa) ? aa.url : false,
          title: aa.title,
          breadcrumbs: ('breadcrumbs' in aa) ? aa.breadcrumbs : true,
          type: aa.type
        }
      ];
      title = aa.title;
    } else {
      if (aa.type === 'group' && 'children' in aa) {
        aa.children.forEach((bb) => {
          if (bb.type === 'item' && 'url' in bb && bb.url === activeLink) {
            result = [
              {
                url: ('url' in bb) ? bb.url : false,
                title: bb.title,
                breadcrumbs: ('breadcrumbs' in bb) ? bb.breadcrumbs : true,
                type: bb.type
              }
            ];
            title = bb.title;
          } else {
            if (bb.type === 'collapse' && 'children' in bb) {
              bb.children.forEach((cc) => {
                if (cc.type === 'item' && 'url' in cc && cc.url === activeLink) {
                  result = [
                    {
                      url: ('url' in bb) ? bb.url : false,
                      title: bb.title,
                      breadcrumbs: ('breadcrumbs' in bb) ? bb.breadcrumbs : true,
                      type: bb.type
                    },
                    {
                      url: ('url' in cc) ? cc.url : false,
                      title: cc.title,
                      breadcrumbs: ('breadcrumbs' in cc) ? cc.breadcrumbs : true,
                      type: cc.type
                    }
                  ];
                  title = cc.title;
                } else {
                  if (cc.type === 'collapse' && 'children' in cc) {
                    cc.children.forEach((dd) => {
                      if (dd.type === 'item' && 'url' in dd && dd.url === activeLink) {
                        result = [
                          {
                            url: ('url' in bb) ? bb.url : false,
                            title: bb.title,
                            breadcrumbs: ('breadcrumbs' in bb) ? bb.breadcrumbs : true,
                            type: bb.type
                          },
                          {
                            url: ('url' in cc) ? cc.url : false,
                            title: cc.title,
                            breadcrumbs: ('breadcrumbs' in cc) ? cc.breadcrumbs : true,
                            type: cc.type
                          },
                          {
                            url: ('url' in dd) ? dd.url : false,
                            title: dd.title,
                            breadcrumbs: ('breadcrumbs' in cc) ? dd.breadcrumbs : true,
                            type: dd.type
                          }
                        ];
                        title = dd.title;
                      }
                    });
                  }
                }
              });
            }
          }
        });
      }
    }
  });
  this.mentorNavigationList = result;
  //console.log("navigation",this.navigationList)
  this.titleService.setTitle(title + ' | NDT Studies ');
}
}
