import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './theme/shared/shared.module';
import { MaterialSharedModule } from './common/MaterialShared.module';

import { AppComponent } from './app.component';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { NavigationComponent } from './theme/layout/admin/navigation/navigation.component';
import { NavContentComponent } from './theme/layout/admin/navigation/nav-content/nav-content.component';
import { NavGroupComponent } from './theme/layout/admin/navigation/nav-content/nav-group/nav-group.component';
import { NavCollapseComponent } from './theme/layout/admin/navigation/nav-content/nav-collapse/nav-collapse.component';
import { NavItemComponent } from './theme/layout/admin/navigation/nav-content/nav-item/nav-item.component';
import { NavBarComponent } from './theme/layout/admin/nav-bar/nav-bar.component';
import { NavLeftComponent } from './theme/layout/admin/nav-bar/nav-left/nav-left.component';
import { NavSearchComponent } from './theme/layout/admin/nav-bar/nav-left/nav-search/nav-search.component';
import { NavRightComponent } from './theme/layout/admin/nav-bar/nav-right/nav-right.component';
import { ConfigurationComponent } from './theme/layout/admin/configuration/configuration.component';

import { ToggleFullScreenDirective } from './theme/shared/full-screen/toggle-full-screen';



/* Menu Items */
import { NavigationItem } from './theme/layout/admin/navigation/navigation';


import { StudentNavigationItem } from './theme/layout/admin/navigation/student-navigation';
import { NgbButtonsModule, NgbDropdownModule, NgbTabsetModule, NgbTooltipModule,NgbNavModule,NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
//import { CountdownModule } from 'ngx-countdown';


import { HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';

import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AngularFileViewerModule } from '@taldor-ltd/angular-file-viewer';
import { AuthInterceptor } from './services/AuthInterceptor-service/AuthInterceptor.service';
import { MatBadgeModule } from '@angular/material/badge';
import { SelectModule } from 'ng-select';
import { MentorNavigationItem } from './theme/layout/admin/navigation/navigation-mentor';
//import { MustMatchDirective } from './lms/lmsadmin/new-userreg/must-match.directive'


//import {NgxDocViewerModule}from 'ngx-doc-viewer';
@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent,
    NavigationComponent,
    NavContentComponent,
    NavGroupComponent,
    NavCollapseComponent,
    NavItemComponent,
    NavBarComponent,
    NavLeftComponent,
    NavSearchComponent,
    NavRightComponent,
    ConfigurationComponent,
    ToggleFullScreenDirective,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    MaterialSharedModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbButtonsModule,
    HttpClientModule,
    NgbTabsetModule,
    NgbNavModule,
    PdfViewerModule,
    AngularFileViewerModule,
    MatBadgeModule,
    NgbPaginationModule,


   // NgxDocViewerModule
  ],
  providers: [{provide:HTTP_INTERCEPTORS,useClass:AuthInterceptor,multi:true},
    NavigationItem,StudentNavigationItem,MentorNavigationItem],
  bootstrap: [AppComponent]
})
export class AppModule { }
