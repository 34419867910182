import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { examPreviewModel } from 'src/app/models/examPreview-edit.model';
import { AuthenticationService } from 'src/app/services/Auth-Service/AuthenticationService.service';
import { UserService } from 'src/app/services/user/user-service.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Location } from '@angular/common'
import {  NavigationEnd } from '@angular/router'

@Component({
  selector: 'app-preview-exam',
  templateUrl: './preview-exam.component.html',
  styleUrls: ['./preview-exam.component.scss']
})
export class PreviewExamComponent implements OnInit {

  questionArray:any;
questionList:any[]=[];
currentindex = 0;
Id:any;
editQuestionView:boolean=false;
previewData:examPreviewModel=new examPreviewModel();
examName
private history: string[] = []
constructor(  private router: Router,
  private route: ActivatedRoute,private authService:AuthenticationService,private service:UserService ,private location: Location) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects)
      }
    })
  }

ngOnInit(): void {
    this.authService.ExampreviewId.subscribe(data => {
    if (data) {
      //////console.log(data,'data');
      this.Id=data;
    //  con
    }
  });
  this.getUserExams(this.Id)

}
getUserExams(examId) {
//debugger;
  this.service.getExamsById(examId).subscribe(result => {
   let respo = <any>result;
   this.questionArray = respo.data;
   this.questionList = respo.data.question;
   this.examName=this.questionArray.examName
    //////console.log(this.questionArray,'List')

    // if( this.questionList[0].NotVisited==true){
    //   this.initalQuestionCheck ()
    // }
    if(this.currentindex==0){
      this.questionList[this.currentindex].NotVisited=false
    }
   /// //co  nsole.log(this.examsList[0].CourseId.subject[0],'l')
   //console.log(this.questionList[0],'exam')

  })
}
initalQuestionCheck (){
  this.questionList[0].NotVisited==false
  //console.log(this.questionList[0],'exam')
}
 onNextClick() {
  this.currentindex++;
  this.questionList[this.currentindex].NotVisited=false;
}
 onQuestion(i) {
  this.currentindex= i;
  this.questionList[this.currentindex].NotVisited=false;
}
 onBack(){
   this.currentindex--;
 }
 onBackList(){
  this.location.back();
//this.router.navigate(['admin/adminhome']);
}
editOpen(q){

  this.editQuestionView=true

  this.previewData=q
  //console.log( 'previewData',this.previewData)
}

// startExam(){
//   this.router.navigate(['start-exam']);

// }
save(){
  //console.log(this.previewData,'dataa')

  let dataPass={
    id:this.previewData.id,
    Question:this.previewData.Question,
    CorrectOption:this.previewData.CorrectOption,
    Description:this.previewData.Description,
    Option1:this.previewData.Option1,
    Option2:this.previewData.Option2,
    Option3:this.previewData.Option3,
    Option4:this.previewData.Option4,
    Option5:this.previewData.Option5,
  }
  //console.log(dataPass,'dataa')
  Swal.fire({
    title: 'Are you sure?',
    text: 'Are you sure to Edit!',
    type: 'warning',
    showCloseButton: true,
    showCancelButton: true
  }).then((willDelete) => {
      if (willDelete.dismiss) {
        Swal.fire('', 'canceld', 'error');
      } else {
        this.service.editQuestion(dataPass).subscribe(result => {
          this.previewData=new examPreviewModel();
        //  this.getAllAssignCourses();
          Swal.fire('Question edit successfully completed. ', '  ', 'success');
          this.editQuestionView=false;
        })

      }
    });

}
cancel(){
  this.editQuestionView=false
}
getBackgroundColor(i:any){
  // debugger;
    // // ////console.log(i,'i')

     if(this.questionList[i].NotVisited==false){

       return  '#c9302c';
     }
     else{
       return '#000000';
     }

   }
}
