import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import{EndpointService} from '../EndPoint/endpoint.service'
import { ModuleModel } from 'src/app/models/module.model';
import { User } from 'src/app/models/chat-talk-model/user.model';
import { USERS as MOCK_USERS } from '../chat-talk/users.mock'
@Injectable({
  providedIn: 'root'
})
export class UserService{

  ///user

  deleteModule() {
    throw new Error('Method not implemented.');
  }


  private token = localStorage.getItem("token");

  private header= new HttpHeaders().set( "Authorization",`Bearer ${this.token}`);
  options = { headers: this.header };

  constructor(private endpoint:EndpointService,private http:HttpClient,) { }






  ///user
  createUser(userData,selectedFile){
        const formdata = new FormData();
    formdata.append('firstName', userData.firstName);
    formdata.append('middleName', userData.middleName);
    formdata.append('lastName', userData.lastName);
    formdata.append('phone', userData.phone);
    formdata.append('whatsapp', userData.whatsapp);
    formdata.append('email', userData.email);
    formdata.append('city', userData.city);
    formdata.append('state', userData.state);
    formdata.append('zip', userData.zip);
    formdata.append('addressLine1', userData.addressLine1);
    formdata.append('addressLine2', userData.addressLine2);
    formdata.append('addressLine3', userData.addressLine3);
    formdata.append('usertype', userData.usertype);
    formdata.append('userName', userData.userName);
    formdata.append('password', userData.password);
    formdata.append('subscriptionHour', userData.subscriptionHour);
    // formdata.append('subscriptionExpired', userData.subscriptionExpired);
    formdata.append('validTo', userData.validTo);
    formdata.append('validFrom', userData.validFrom);
    formdata.append('course', userData.course);
    formdata.append('trainingTo', userData.trainingTo);
    formdata.append('trainingFrom', userData.trainingFrom);

    formdata.append('trainingTimeTo', userData.trainingTimeTo);
    formdata.append('trainingTimeFrom', userData.trainingTimeFrom);
    formdata.append('training', userData.training);
    formdata.append('active', userData.active);

    if (selectedFile) {
      formdata.append('profilePic',selectedFile);
    }
    return this.http.post(this.endpoint.createUser,formdata,this.options);
  }
  userRegister(userData,selectedFile){
   debugger;
    //console.log('userDataPass',userData)
    const formdata = new FormData();
    formdata.append('firstName', userData.firstName);
    formdata.append('middleName', userData.middleName);
    formdata.append('lastName', userData.lastName);
    formdata.append('phone', userData.phone);
    formdata.append('whatsapp', userData.whatsapp);
    formdata.append('email', userData.email);
    formdata.append('city', userData.city);
    formdata.append('state', userData.state);
    formdata.append('zip', userData.zip);
    formdata.append('addressLine1', userData.addressLine1);
    formdata.append('addressLine2', userData.addressLine2);
    formdata.append('addressLine3', userData.addressLine3);
    formdata.append('usertype', userData.usertype);
    formdata.append('userName', userData.userName);
    formdata.append('password', userData.password);
    formdata.append('validTo', userData.validTo);
    formdata.append('validFrom', userData.validFrom);
    formdata.append('course', userData.course);
    formdata.append('trainingTo', userData.trainingTo);
    formdata.append('trainingFrom', userData.trainingFrom);
    formdata.append('trainingTimeTo', userData.trainingTimeTo);
    formdata.append('trainingTimeFrom', userData.trainingTimeFrom);
    formdata.append('training', userData.training);
    formdata.append('active', userData.active);

    if (selectedFile) {
      formdata.append('profilePic',selectedFile);
    }
    return this.http.post(this.endpoint.userRegister,formdata,this.options);
  }
  getAllUser(){
    //debugger;
    // this.headers = AuthenticationService.authorizeHeader(this.headers);

    let header = new HttpHeaders().set(
      "Authorization",`Bearer ${this.token}`

    );
    return this.http.get(this.endpoint.getAllUser,this.options);
    // return this.http.get(this.endpoint.getAllUser, {headers:header} );
  }
  getUserById(id: any){
    return this.http.get(this.endpoint.getUserById+id,this.options);
  }
  updateUserById(userData:any,id: any,selectedFile){
   debugger;
   const formdata = new FormData();
   formdata.append('firstName', userData.firstName);
   formdata.append('middleName', userData.middleName);
   formdata.append('lastName', userData.lastName);
   formdata.append('phone', userData.phone);
   formdata.append('whatsapp', userData.whatsapp);
   formdata.append('email', userData.email);
   formdata.append('city', userData.city);
   formdata.append('state', userData.state);
   formdata.append('zip', userData.zip);
   formdata.append('addressLine1', userData.addressLine1);
   formdata.append('addressLine2', userData.addressLine2);
   formdata.append('addressLine3', userData.addressLine3);
   formdata.append('usertype', userData.usertype);
   formdata.append('userName', userData.userName);
   formdata.append('password', userData.password);
   formdata.append('imageEdit', userData.imageEdit);

   if (selectedFile) {
     formdata.append('profilePic',selectedFile);
   }
   //console.log(formdata)
  return this.http.put(this.endpoint.updateUserById+id,formdata,);

  }
async deleteUserById(id: any){
    debugger;
    const response = await this.http.delete<HttpResponse<any>>(this.endpoint.deleteUserById+id, {
        observe: 'response',
      })
      .toPromise();
    return response;
  }

/***********************MODULE**************** */
createModule(formData: ModuleModel) {
  return this.http.post(this.endpoint.createModule,formData,this.options);
}

getAllModule() {
  return this.http.get(this.endpoint.getAllModule,this.options);
}

getModuleById(id:any){
  return this.http.get(this.endpoint.getMOduleById+id,this.options);
}
async deleteModuleById(id: any){
  const response = await this.http.delete<HttpResponse<any>>(this.endpoint.deleteModuleById+id, {
     observe: 'response',
   })
   .toPromise();
 return response;
}
updateModuleById(UpdateData:any,id: any){
  debugger;
 return this.http.put(this.endpoint.updateModuleById+id,UpdateData,this.options);
 }
//////
getAdminDashboard() {
  return this.http.get(this.endpoint.getAdminDashboard,this.options);
}
getUserListFordashboard() {
  return this.http.get(this.endpoint.getUserListFordashboard,this.options);
}

getUserdashboard(id) {
  return this.http.get(this.endpoint.getUserDashboard+id,this.options);
}
 /*************************************course*******************************************/
  createCourse(CourseData){
    return this.http.post(this.endpoint.createCourse,CourseData,this.options);
  }
  getAllCourses(){
   //console.log(this.token, 'token');
   //console.log(this.options, 'options');
    return this.http.get(this.endpoint.getAllCourses,this.options);
  }
  getCourseById(id:any){
    return this.http.get(this.endpoint.getCourseById+id,this.options);
  }
  getCourseByModule(id: any) {
    return this.http.get(this.endpoint.getCourseByModule+id,this.options);
  }
  async deleteCourseById(id: any){
    debugger;
    const response = await this.http.delete<HttpResponse<any>>(this.endpoint.deleteCourseById+id, {
        observe: 'response',
      })
      .toPromise();
    return response;
  }
  updateCourseById(UpdateData:any,id: any){
   debugger;
  return this.http.put(this.endpoint.updateCourseById+id,UpdateData,this.options);
  }
 //subjecdt
  createSubject(subjectData){
    return this.http.post(this.endpoint.createSubject,subjectData,this.options);
  }
  getAllSubject()
  {

    return this.http.get(this.endpoint.getAllSubjects,this.options);
  }
  getSubjectById(id:any){
    return this.http.get(this.endpoint.getSubjectById+id,this.options);
  }
  async deleteSubjectById(id: any){
     const response = await this.http.delete<HttpResponse<any>>(this.endpoint.deleteSubjectById+id, {
        observe: 'response',
      })
      .toPromise();
    return response;
  }
  updateSubjectById(UpdateData:any,id: any){
   debugger;
  return this.http.put(this.endpoint.updateSubjectById+id,UpdateData,this.options);
  }
 //subjecdt
  createUnit(unitData){
    return this.http.post(this.endpoint.createUnit,unitData,this.options);
  }
  getAllUnit()
  {

    return this.http.get(this.endpoint.getAllUnits,this.options);
  }
  getUnitById(id:any){
    return this.http.get(this.endpoint.getUnitById+id,this.options);
  }
  async deleteUnitById(id: any){
     const response = await this.http.delete<HttpResponse<any>>(this.endpoint.deleteUnitById+id, {
        observe: 'response',
      })
      .toPromise();
    return response;
  }

  updateUnitById(UpdateData:any,id: any){
   //debugger;
  return this.http.put(this.endpoint.updateUnitById+id,UpdateData,this.options);
  }

  ////studay material
    createStudyMaterial(StudayMaterial:any,selectedFile){
     debugger;
      //console.log(StudayMaterial,'material pass value')
      //console.log(StudayMaterial.unit,'unit')
      // let unitvalue={
      // unitName:StudayMaterial.unit.unitName,
      //  id:StudayMaterial.unit.id,
      //  Description:StudayMaterial.unit.Description,
      // }
      const formdata = new FormData();

    formdata.append('meterialName', StudayMaterial.meterialName);
    formdata.append('description', StudayMaterial.description);
    formdata.append('Type', StudayMaterial.Type);
    formdata.append('externalURL', StudayMaterial.externalURL);
    formdata.append('unit',StudayMaterial.unit);
    if (selectedFile) {
      formdata.append('Materialfile',selectedFile);
    }
     //console.log(formdata,'formmmm  mmmmmm')
      return this.http.post(this.endpoint.createStudyMaterial,formdata,this.options);
  }

   getAllStudyMaterial()
  {

    return this.http.get(this.endpoint.getAllStudyMaterial,this.options);
  }
  getMeterialByUser(id:any){
    return this.http.get(this.endpoint.getMeterialByUser+id,this.options);
  }
  filtereMeterials(data){
    return this.http.post(this.endpoint.filtereMeterials,data,this.options);

  }

  updatematerialById(StudayMaterial:any,id){


    return this.http.put(this.endpoint.updateMaterialById+id,StudayMaterial,this.options);
  }
  async deleteMeterials(id){
    const response = await this.http.delete<HttpResponse<any>>(this.endpoint.deleteMeterials+id, {
      observe: 'response',
    })
    .toPromise();
  return response;
  }
  ///////question upload exam
  uploadQuestion(questionData:any,selectedFile){
     debugger;

      const formdata = new FormData();
    // formdata.append('courseName', StudayMaterial.courseName);
    formdata.append('subject', questionData.subject);
    formdata.append('examName', questionData.examName);
    formdata.append('minimumMarks', questionData.minimumMarks);
    formdata.append('examPrice', questionData.examPrice);
    formdata.append('allowAttempt', questionData.allowAttempt);
    formdata.append('maxAttempts', questionData.maxAttempts);
    formdata.append('correctScore', questionData.correctScore);
    formdata.append('incorrectScore', questionData.incorrectScore);
    formdata.append('duration', questionData.duration);
    formdata.append('endDate', questionData.endDate);
    formdata.append('startDate', questionData.startDate);
    formdata.append('examType', questionData.examType);
    if (selectedFile) {
      formdata.append('questionFile',selectedFile);
    }

      return this.http.post(this.endpoint.uploadQuestions,formdata,this.options);
  }

   getAllExams()
  {

    return this.http.get(this.endpoint.getAllExams,this.options);
  }
 async deleteExam(id){
  const response = await this.http.delete<HttpResponse<any>>(this.endpoint.deleteExam+id, {
    observe: 'response',
  })
  .toPromise();
return response;
  }
    //singel questio
    createQuestionBank(questionData,supportFile){
      const formdata = new FormData();
      formdata.append('type',questionData.type);
      formdata.append('level',questionData.level);
      formdata.append('Question',questionData.Question);
      formdata.append('Description',questionData.Description);
      formdata.append('answer',questionData.answer);
      formdata.append('Option1',questionData.Option1);
      formdata.append('Option2',questionData.Option2);
      formdata.append('Option3',questionData.Option3);
      formdata.append('Option4',questionData.Option4);
      formdata.append('Option5',questionData.Option5);
      formdata.append('CorrectOption',questionData.CorrectOption);
      formdata.append('course',questionData.course);
      formdata.append('subject',questionData.subject);
      formdata.append('unit',questionData.unit);

      if (supportFile) {
        formdata.append('supportFile',supportFile);
      }


      return this.http.post(this.endpoint.createQuestionBank,formdata,this.options);
    }
    async deleteQuestionBank(id){
      const response = await this.http.delete<HttpResponse<any>>(this.endpoint.deleteQuestionBank+id, {
        observe: 'response',
      })
      .toPromise();
    return response;
    }
    editQuestionBank(data){

     return this.http.put(this.endpoint.editQuestionBank,data,);
    }
    deleteMultipleQuestionBank(Data){
      return this.http.post(this.endpoint.deleteMultipleQuestionBank,Data,this.options);
    }
  ///////login

   login(loginData){
    return this.http.post(this.endpoint.login,loginData,this.options);
  }

    ///////Assign course
  assignCourse(assignCourseData){
    return this.http.post(this.endpoint.assignCourse,assignCourseData,this.options);
  }
  getAllAssignourse(){
    return this.http.get(this.endpoint.getAllAssignourse,this.options);
  }
  getUsersToApprove(){
    return this.http.get(this.endpoint.getUsertoApprove,this.options);
  }
  editAssignCourses(data){
    let id=data.id
    // console.log(data,'id')
    return this.http.put(this.endpoint.editAssignCourses+id,data,);
  }
 async  deleteAssignCourses(id){
    const response = await this.http.delete<HttpResponse<any>>(this.endpoint.deleteAssignCourses+id, {
      observe: 'response',
    })
    .toPromise();
  return response;
  }


  ///getUserCourses/:userId
  getUserCoursesUId(id:any){
     return this.http.get(this.endpoint.getUserCoursesUId+id,this.options);
  }
  getUserCoursesTree(id:any){
     return this.http.get(this.endpoint.getCourseTree+id,this.options);
  }
  ////exam
  getUserExams(id:any){
     return this.http.get(this.endpoint.getUserExams+id,this.options);
  }
  getExamsById(id:any){
     return this.http.get(this.endpoint.getExamsById+id,this.options);
  }
  saveExamResults(resultData){

     return this.http.post(this.endpoint.saveExamResults,resultData,this.options);

  }
  getExamResultById(id :any){
    return this.http.get(this.endpoint.getExamResultById+id,this.options);
  }
  getAllExamResultByuser(id:any){
    return this.http.get(this.endpoint.getAllExamResultByuser+id,this.options)
  }
  getAllExamResult(){
    return this.http.get(this.endpoint.getAllExamResult,this.options)
  }
  filterResult(data){
    return this.http.post(this.endpoint.filterResult,data,this.options);

  }
  FilterExam(Data){
    return this.http.post(this.endpoint.FilterExam,Data,this.options);
  }
  getAllQuestionBank(){
    return this.http.get(this.endpoint.getAllQuestionBank,this.options);
  }

  /*notification*/
  createNotification(Data){
    return this.http.post(this.endpoint.createNotification,Data,this.options);
  }
 getNotificationBYId(id:any){
     return this.http.get(this.endpoint.getNotifations+id,this.options);
  }
  getAllNotifations(){
  return this.http.get(this.endpoint.getAllNotifations,this.options)
}

 resadnotification(id:any){
     return this.http.get(this.endpoint.resadnotification+id,this.options);
  }
  async deleteNotification(id: any){
    const response = await this.http.delete<HttpResponse<any>>(this.endpoint.deletenotification+id, {
       observe: 'response',
     })
     .toPromise();
   return response;
  }
/////submitUserApproval

submitUserApproval(Data){
  return this.http.post(this.endpoint.submitUserApproval,Data,this.options);
}

///forget password
getpasswordbyId(id:any){
return this.http.post(this.endpoint.getpasswordbyId,id,this.options);
}
//reset password
resetPasswordUrl(Data)
{
  return this.http.post(this.endpoint.resetPasswordUrl,Data,this.options);
}

///editQuestion
editQuestion(data){
console.log('edit que',data);

  return this.http.put(this.endpoint.editQuestion,data,this.options);

}
////filter

resetAdminPassword(Data)
{
  return this.http.put(this.endpoint.resetAdminPassword,Data,this.options);
}
/////createaccount
createaccount(data){
  return this.http.post(this.endpoint.createaccount,data,this.options);
}

getAllAccount(){
  return this.http.get(this.endpoint.getAllAccount,this.options)
}

editAccount(data:any){
  return this.http.put(this.endpoint.editAccount,data,this.options);
}
getPermissionsByName(name){
  return this.http.get(this.endpoint.getPermissionsByName+name,this.options);
}
//getCourseTreeForAdmin
getCourseTreeForAdmin(){
  return this.http.get(this.endpoint.getCourseTreeForAdmin,this.options)
}
////changeUnitOrder
changeUnitOrder(data){
  return this.http.post(this.endpoint.changeUnitOrder,data,this.options);

}
changeSubjectOrder(data){
  return this.http.post(this.endpoint.changeSubjectOrder,data,this.options);

}
///feedback
createFeedBack(data){
  return this.http.post(this.endpoint.createFeedBack,data,this.options);

}
getAllFeedback(){
  return this.http.get(this.endpoint.getAllFeedback,this.options)

}
getFeedbackById(id){
  return this.http.get(this.endpoint.getFeedbackById+id,this.options)

}
///sharingExperiance
sharingExperianceByUser(data,experianceFile){
  const formdata = new FormData();
  formdata.append('user',data.user);
  formdata.append('course',data.course);
  formdata.append('approved',data.approved);
  formdata.append('title',data.title);
  formdata.append('experience',data.experience);
  if (experianceFile) {
    formdata.append('experianceFile',experianceFile);
  }
  return this.http.post(this.endpoint.sharingExperianceByUser,formdata,this.options);

}
sharingExperianceByAdmin(data,experianceFile){
  const formdata = new FormData();
  formdata.append('user',data.user);
  formdata.append('course',data.course);
  formdata.append('approved',data.approved);
  formdata.append('title',data.title);
  formdata.append('experience',data.experience);
  if (experianceFile) {
    formdata.append('experianceFile',experianceFile);
  }
  return this.http.post(this.endpoint.sharingExperianceByAdmin,formdata,this.options);

}
getAllExperience(){
  return this.http.get(this.endpoint.getAllExperience,this.options)

}
getExperianceToApprove(){
  return this.http.get(this.endpoint.getExperianceToApprove,this.options)

}
getExperience(id){
  return this.http.get(this.endpoint.getExperience+id,this.options)

}
getExperienceByUser(id){
  return this.http.get(this.endpoint.getExperienceByUser+id,this.options)

}
approveExperiance(id){
  return this.http.put(this.endpoint.approveExperiance+id,this.options)

}
async  RejectExperiance(id){
  const response = await this.http.delete<HttpResponse<any>>(this.endpoint.RejectExperiance+id, {
    observe: 'response',
  })
  .toPromise();
return response;
}


///get Certificate
getCertificates(id){
  return this.http.get(this.endpoint.getCertificate+id,this.options)
}
getUserCourseById(id){
  return this.http.get(this.endpoint.getUserCoursesById+id,this.options)
}
///Training Appointment Sheduleapi/trainingShedule/createShedule
createShedule(data){
  return this.http.post(this.endpoint.createShedule,data,this.options);
}
createSheduleByAdmin(data){
  return this.http.post(this.endpoint.createSheduleByAdmin,data,this.options);
}
getAllShedules(){
  return this.http.get(this.endpoint.getAllShedules,this.options)
}
getApprovedShedule(){
  return this.http.get(this.endpoint.getApprovedShedule,this.options)
}
getAppointmentUserId(id){
  return this.http.get(this.endpoint.getAppointmentUserId+id,this.options)
}
approveShedule(data,id){
  return this.http.put(this.endpoint.approveShedule+id,data,this.options);

}
async  deleteShedule(id){
  const response = await this.http.delete<HttpResponse<any>>(this.endpoint.deleteShedule+id, {
    observe: 'response',
  })
  .toPromise();
return response;
}

getSheduleforApprove(){
  return this.http.get(this.endpoint.getSheduleforApprove,this.options)

}

    ///createQBExam
    createQBExam(data){
      return this.http.post(this.endpoint.createQBExam,data,this.options);
    }
    getAllQBExam(){
      return this.http.get(this.endpoint.getAllQBExam,this.options);

    }
    getQBExam(id:any){
      return this.http.get(this.endpoint.getQBExam+id,this.options);
   }
   getQBExamQuestions(id:any){
      return this.http.get(this.endpoint.getQBExamQuestions+id,this.options);
   }
   getQuestionBankByunit(id:any){
      return this.http.get(this.endpoint.getQuestionBankByunit +id,this.options);
   }
   getQuestionBankBycourse(id:any){
      return this.http.get(this.endpoint.getQuestionBankBycourse +id,this.options);
   }
   getQuestionBankBySubject(id:any){
      return this.http.get(this.endpoint.getQuestionBankBySubject +id,this.options);
   }
  async deleteQBExam(id){
    const response = await this.http.delete<HttpResponse<any>>(this.endpoint.deleteQBExam+id, {
      observe: 'response',
    })
    .toPromise();
  return response;
   }
   getUserQBExams(id){
    return this.http.get(this.endpoint.getUserQBExams +id,this.options);

   }
   getUserQBpracticeExams(id){
    return this.http.get(this.endpoint.getUserQBpracticeExams +id,this.options);

   }
   filterQbExam(Data){
    return this.http.post(this.endpoint.filterQbExam,Data,this.options);
   }
   ///password
  requestPasswordReset(data){
    return this.http.post(this.endpoint.requestPasswordReset,data,this.options);
  }
  getAllResetRequests(){
    return this.http.get(this.endpoint.getAllResetRequests,this.options);

  }
  ///
  createAdvertisement(data,advertiseImage){
    const formdata = new FormData();

    formdata.append('title',data.title);
   formdata.append('module',data.module);
    if (advertiseImage) {
      formdata.append('advertiseImage',advertiseImage);
    }
    return this.http.post(this.endpoint.createAdvertisement,formdata,this.options);

  }
  getAllAdvertisement(){
    return this.http.get(this.endpoint.getAllAdvertisement,this.options)

  }
  getAdvertisement(id){
    return this.http.get(this.endpoint.getAdvertisement+id,this.options)

  }
  getAdvertisementById(id: any) {
    return this.http.get(this.endpoint.getAdvertisementById+id,this.options)
  }
  ////getAllMCQQuestion
  getAllMCQuestion() {
    return this.http.get(this.endpoint.getAllQuestion,this.options)
  }
  ////exl upload
  createQuestionBankfromExcel(data){
    return this.http.post(this.endpoint.createQuestionBankfromExcel,data,this.options);

  }
  getUserForUsername(username: string) : Promise<User> {
    return new Promise((resolve) => {
        resolve(MOCK_USERS.find(u => u.username.toLocaleLowerCase() === username.toLocaleLowerCase()));
    });
}
//////delete
async deleteAdvertisement(id: any){
  const response = await this.http.delete<HttpResponse<any>>(this.endpoint.deleteAdvertisement+id, {
     observe: 'response',
   })
   .toPromise();
 return response;
}
async DeleteFeedbackById(id: any){
  const response = await this.http.delete<HttpResponse<any>>(this.endpoint.DeleteFeedbackById+id, {
     observe: 'response',
   })
   .toPromise();
 return response;
}
async deleteEamResult(id: any){
  const response = await this.http.delete<HttpResponse<any>>(this.endpoint.deleteEamResult+id, {
     observe: 'response',
   })
   .toPromise();
 return response;
}

  }
