import {
  HttpHeaders,
  HttpErrorResponse,
  HttpClient,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, throwError } from 'rxjs';
import { User } from 'src/app/models/chat-talk-model/user.model';
import { UserService } from '../user/user-service.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  examResult = new BehaviorSubject<any>(null);
  examResultt = new BehaviorSubject<any>(null);
  examData = new BehaviorSubject<any>(null);
  ExamId= new BehaviorSubject<any>(null);
  ExampreviewId= new BehaviorSubject<any>(null);
  QuestionBankExamId=new BehaviorSubject<any>(null);

  private static SAVED_USER_STORAGE_KEY = 'userTest';
  //   static readonly authorizationKey: string = 'Authorization';
  //   static readonly tokenStorageKey = 'currentUserToken';
  //   static authorizationValue: string;
  //   static readonly userEmailStorageKey = 'currentUserUseremail';
  //   /*========get authorized header*/
  // static authorizeHeader(headers: HttpHeaders) {
  //   AuthenticationService.authorizationValue =
  //     'Bearer ' + AuthenticationService.getAuthToken();
  //   if (!headers.has(AuthenticationService.authorizationKey)) {
  //     return headers.append(
  //       AuthenticationService.authorizationKey,
  //       AuthenticationService.authorizationValue
  //     );
  //   } else {
  //     return headers.set(
  //       AuthenticationService.authorizationKey,
  //       AuthenticationService.authorizationValue
  //     );
  //   }
  // }
  //   static getAuthToken(): string {
  //   const sessionToken = sessionStorage.getItem(
  //     AuthenticationService.tokenStorageKey
  //   );
  //   const persistentToken = localStorage.getItem(
  //     AuthenticationService.tokenStorageKey
  //   );
  //   if (sessionToken) {
  //     return sessionToken;
  //   } else if (persistentToken) {
  //     sessionStorage.setItem(
  //       AuthenticationService.tokenStorageKey,
  //       persistentToken
  //     );
  //     sessionStorage.setItem(
  //       AuthenticationService.userEmailStorageKey,
  //       localStorage.getItem(AuthenticationService.userEmailStorageKey)
  //     );
  //     return persistentToken;
  //   }
  //   return '';
  // }

  //  /* ==========to handle error ======== */
  // errorHandler(errorResponse: HttpErrorResponse) {
  //   let errMsg: string;
  //   if (errorResponse.error instanceof ErrorEvent) {
  //     const error = errorResponse.error;
  //     //console.log(errorResponse.error);
  //   } else {
  //   }

  //   return throwError(JSON.stringify(errorResponse.status));
  // }
  constructor(
    private userService: UserService,){
    }
  getCurrentUser() : Promise<User> {
    return new Promise((resolve) => {
       // const currentUserUsername = localStorage.getItem(AuthenticationService.SAVED_USER_STORAGE_KEY);
        const currentUserUsername = localStorage.getItem(AuthenticationService.SAVED_USER_STORAGE_KEY);
        //console.log(localStorage.getItem(AuthenticationService.SAVED_USER_STORAGE_KEY),'user')

        //console.log(currentUserUsername,'user')
        if (currentUserUsername) {
            this.userService.getUserForUsername(currentUserUsername).then(user => {
                resolve(user);
                //console.log(user,'user')
            });
        } else {
            reject(null);
        }
    });
}
}
function reject(arg0: null) {
  throw new Error('Function not implemented.');
}

