<ul class="navbar-nav ml-auto custom-navCls">
  <li class="m-r-5"> <div class="dropdown" ngbDropdown placement="auto">
    <a ngbDropdownToggle href="javascript:" data-toggle="dropdown"><i [matBadge]="badgeContent"  mat-raised-button matBadgePosition="'above'"matBadgeSize="small" matBadgeColor="warn"  class="icon feather icon-bell"></i></a>
    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right notification">
      <!-- <div class="noti-head" style="background:#2a75b6;" *ngFor="let item of notificationList; let i = index">
        <h4 class="d-inline-block m-b-0 " (click)="readMessage(item.id)">{{item.notification}}</h4>
        <div class="float-right">
          <a href="javascript:" class="m-r-10" (click)="resadNotification(item.id)">mark as read</a>
          <a href="javascript:">clear all</a>
        </div>
      </div> -->
    <div class="noti-head">
      <h4 class="d-inline-block m-b-0">Notifications</h4>
      <div class="float-right">

      </div>
    </div>
    <perfect-scrollbar [style.max-height]="'300px'">
      <ul class="noti-body" >
        <li class="n-title">
          <p class="m-b-0">NEW</p>
        </li>
        <li class="notification" >
          <div class="media" *ngFor="let item of notificationList; let i = index">
            <div class="media-body m-t-5 "   *ngIf="!item.isRead">
              <p><strong>{{item.title}}</strong><span class="n-time text-muted">
                <a (click)="readMessage(item.id,i)"><i class="icon feather icon-eye m-r-10"></i></a>
                  </span></p>
              <p class="noti-cls_p"><span>{{item.notification}}</span></p>
                </div>
          </div>
        </li>

      </ul>
    </perfect-scrollbar>
    <div class="noti-footer">
      <a href="javascript:" [routerLink]="['/user/Notification/show-All-Notification']">show all</a>
    </div>
    </div>
      </div>
      </li>
      <li class="m-r-10 border-right"><a class=""></a></li>
      <li><p>{{Uname}}</p></li>

<li>
    <div class="dropdown drp-user" ngbDropdown placement="auto">
      <a href="javascript:" ngbDropdownToggle data-toggle="dropdown">
        <!-- <i class="icon feather icon-user"></i> -->
        <img [src]="imgUrl1" class="img-radius icon img-icon-nav" alt="User-Profile-Image">
      </a>
      <div class="dropdown-menu dropdown-menu-right profile-notification" ngbDropdownMenu>
        <div class="pro-head">
          <img  [src]="imgUrl1" class="img-radius" alt="User-Profile-Image">
          <!-- <img *ngIf="user.image.length<=0" src="{{urlpath+imgUrl}}" class="img-radius" alt="User-Profile-Image"> -->
          <span>{{Uname}}</span>
          <a href="javascript:" class="dud-logout" title="Logout" (click)="logout()">
            <i class="feather icon-log-out"></i>
          </a>
        </div>
        <ul class="pro-body">
          <li><a href="javascript:" class="dropdown-item" (click)="logout()"><i class="feather icon-log-out m-r-5" ></i>Logout</a></li>
         </ul>
      </div>
    </div>
  </li>
</ul>
