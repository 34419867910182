
  <app-card cardTitle="Notification" [options]="false">
    <div class="row">

      <div class="col-sm-12">

          <div class="table-responsive">
            <table class="table table-striped valign-middle">
            <thead>
              <tr>
                <th class="disabled center aligned">Sl no</th>

                <th data-sort="string" class="asc">Title</th>
                <th data-sort="string" class="asc">Message</th>
                <th class="disabled center aligned" width="15%">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of notificationList| slice: (page-1) * pageSize : (page-1) * pageSize + pageSize ; let i=index">
                <td>{{ i + 1 }}</td>

                <td>{{item.title}}</td>
                <td>{{item.notification| slice:0:10}}</td>

                <td class="table-action">

                        <a  class="btn-sm btn-icon btn-outline-primary" (click)="viewMessage(item.id,item)"><i class="feather icon-eye"></i></a>

                </td>
              </tr>
            </tbody>
          </table>
          </div>
          <div class="m-t-10">
            <ngb-pagination [pageSize] = "pageSize" [collectionSize]="notificationCount" [(page)]="page" [boundaryLinks]="true"></ngb-pagination>

            <hr>

            <pre>Current page: {{page}}</pre>
           </div>

      </div>
    </div>
    </app-card>

