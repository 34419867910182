import {Injectable} from '@angular/core';

export interface StudentNavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: SNavigation[];
}

export interface SNavigation extends StudentNavigationItem {
  children?: StudentNavigationItem[];
}

const StudentNavigationItems = [

  /**/
  {
    id: 'Home',
    title: 'Home',
    type: 'group',
    icon: 'feather icon-align-left',
    children: [
      {
        id: 'Profile',
        title: 'Profile',
        type: 'collapse',
        icon: 'feather icon-home',
        children: [
          {
            id: 'Adminhome',
            title: 'Dashboard',
            type: 'item',
            url: '/user/studenthome',
          },

          {
            id: 'My Account',
            title: 'My Account',
            type: 'item',

            url: '/user/myaccount',

          },

        ]
      }
    ]
  },

  {
    id: 'course',
    title: 'Course Details',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [

        {
            id: 'CourseList',
            title: 'Course Content',
            type: 'item',

            icon: 'feather icon-book',
            url: '/user/course-list'
          },
        {
            id: 'Studying',
            title: 'Studying',
            type: 'item',
            icon: 'feather icon-file',
            //url:'/user/progress'
             url: '/user/Studying'
          },
          //  {
          //    id: 'Studymaterials',
          //     title: 'Chat',
          //    type: 'item',
          //    icon: 'feather icon-message-circle',

          //  url:'/user/chat',
          //  },
              {
                id: 'QBpractice',
                title: 'QB Practices',
                type: 'item',
                icon: 'feather icon-clipboard',
                url:'/user/QBpractice-List'
                },
              {
                id: 'QBpractice',
                title: 'QB Questions',
                type: 'item',
                icon: 'feather icon-clipboard',
                url:'/user/QB-questions-List'
                },
    ]
  },
  {
    id: 'CBT Exam',
    title: 'Exam List',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      // {
      //   id: 'Exam Details',
      //   title: 'MCQ Exam List',
      //   type: 'collapse',
      //   icon: 'feather icon-edit',
      //   children: [
      //     {
      //       id: 'Model Exams',
      //       title: 'Model Exams',
      //       type: 'item',
      //       url: '/user/Model-exam-list'
      //     },
      //     {
      //       id: ' QB Exams',
      //       title: 'QB Exams',
      //       type: 'item',
      //       url: '/user/QB-exam-list'
      //     },

      //     {
      //       id: 'VIHQA',
      //       title: 'VIHQA',
      //       type: 'item',
      //       url: '/user/VIHQA-exam-list'
      //     },
      //   ]
      // },
      {
        id: 'Exam Details',
        title: 'Exam List',
        type: 'collapse',
        icon: 'feather icon-edit',
        children: [

          {
            id: ' QB Exams',
            title: 'QB Exams',
            type: 'item',
            url: '/user/CBT-QB-exam-list'
          },
          {
            id: 'Model Exams',
            title: 'Model Exams',
            type: 'item',
            url: '/user/CBT-Model-exam-list'
          },
          {
            id: 'VIHQA',
            title: 'VIHQA',
            type: 'item',
            url: '/user/CBT-VIHQA-exam-list'
          },
        ]
      },
      {

        id: 'result',
        title: 'Result Review',
        type: 'collapse',
        icon: 'feather icon-folder',
        children: [
          // {
          //   id: 'Result List',
          //   title: 'Result List',
          //   type: 'item',
          //   url: '/user/resultlist'
          // },
          {
            id: ' QB Exams',
            title: 'QB Exams',
            type: 'item',
            url: '/user/QB-result-list'
          },
          {
            id: 'Model Exams',
            title: 'Model Exams',
            type: 'item',
            url: '/user/Model-result-list'
          },


          {
            id: 'VIHQA',
            title: 'VIHQA',
            type: 'item',
            url: '/user/VIHQA-result-list'
          },
        ]
      },
    ]
  },
  {

    id: 'pages',
    title: 'Pages',
    type: 'group',
    icon: 'feather icon-folder',
    children: [

      {
        id: 'appointment',
      title: 'Training Appointment',
      type: 'collapse',
      icon: 'feather icon-monitor',
      children: [
        {
          id: 'TrainingAppointmentT',
          title: 'Request For Training',
          type: 'item',
          url:'/user/training-appointment'
        },
        {
          id: 'TrainingAppointment',
          title: 'Requested Training Appointement',
          type: 'item',
          url:'/user/training-appointment-list',
        },
      ]
    },
    {
      id: 'notification',
      title: 'Read Notification',
      type: 'item',
      icon: 'feather icon-mail',
      url:'/user/Notification/show-All-Notification',
      },
      {
        id: 'feedback',
        title: 'User Feedback',
        type: 'item',
        icon: 'feather icon-message-circle',
        url: '/user/feedback'
        },
      // {
      //   id: 'Recent Info',
      //   title: 'Recent Info',
      //   type: 'item',
      //   icon: 'feather icon-info',
      //   url: '/user/advertisement'
      //   },

          {
            id: 'SharingExperience',
          title: 'Sharing Experience',
          type: 'collapse',
          icon: 'feather icon-monitor',
          children: [
          {
            id: 'sharing experience',
            title: 'Compose',
            type: 'item',

            url:'/user/sharing-experience'
            },
          {
            id: 'experience',
            title: 'Users Experience',
            type: 'item',

            url:'/user/user-experience'
            },
          ]},

            {
              id: 'advertisement',
              title: 'View Advertisement',
              type: 'item',
              icon: 'feather icon-tv',
              url:'/user/advertisement'
              },

        {
          id: 'training certificates',
          title: 'Training Certificates',
          type: 'item',
          icon: 'feather icon-award',
          url:'/user/training-certificateList'
          },

    ]
      },
      {

        id: 'Helpdesk',
        title: 'Helpdesk',
        type: 'group',
        icon: 'feather icon-folder',
        children: [
      {
        id: 'Contact Us',
        title: 'Contact Us',
        type: 'item',
        icon: 'feather icon-phone',
        url:'/user/contact-us'
        },
      ]
      },
      {
            id: 'LogoutId',
            title: 'Logout',
            type: 'item',
            icon: 'feather icon-log-out',
            url:'/auth/logout'
            },

      // {
      //   id: 'Results',
      //   title: 'Results',
      //   type: 'collapse',
      //   icon: ' feather icon-package',
      //    children: [
      //     {
      //       id: 'ResulrtList',
      //       title: 'Result List',
      //       type: 'item',
      //       url: '/admin/result-list'
      //     },
      //   ]
      // },








]

@Injectable()
export class StudentNavigationItem {
  public get() {
    return StudentNavigationItems;
  }

}
