import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import { UserService } from './services/user/user-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  userType=localStorage.getItem('userType');
  constructor(private router: Router,private service:UserService) { }
  public dataPermission:string;
  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this.getPermissionsByName(this.userType);
    // document.addEventListener('contextmenu', function(e) {
    //   e.preventDefault();
    // });
  }
  getPermissionsByName(type){
    this.service.getPermissionsByName(type).subscribe(result=>{
      let res=<any>result;
      this.dataPermission=res
     // console.log(this.dataPermission,'ressss')
    });
  }
}
