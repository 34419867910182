
<div class="card">
  <div class="header-custom">
    <div class="row">
      <div class=" col-sm-6">
        <h1 class="">{{examName}}</h1>

      </div>

    </div>
  </div>

<div class="row examBody">
<fieldset class="form-group col-sm-9   cnt-bdy_style" >
 <div class=" m-t-10" align="end">
   <button class="btn btn-primary m-r-5" (click)="editOpen(questionList[currentindex].question)">
    <i class="feather icon-edit"></i>
    Edit a Question</button>
   <button class="btn btn-Refresh" (click)="cancel()">Refresh</button>
 </div>
  <div class="m-l-10 m-t-10" *ngIf="!editQuestionView">

    <span class="form-row ">{{ currentindex +1 }})  <label  [innerHTML]="questionList&& questionList[currentindex].question.Question">
      ?</label></span>
      <span  class="form-row" *ngIf="questionList[currentindex].question.filePath!=null">
        <img  [src]="urlpath+questionList[currentindex].question.filePath" class=" form-row prf_img">

      </span>
      <span *ngIf="questionList[currentindex].question.type=='Multiple Choice Single Answer'" >
    <span class="form-row Cm-syle">A)<label  [innerHTML]="questionList&& questionList[currentindex].question.Option1 ">
    </label></span>
    <span class="form-row Cm-syle">B)<label  [innerHTML]="questionList&& questionList[currentindex].question.Option2"></label>
    </span>
    <span class="form-row Cm-syle">C)<label  [innerHTML]="questionList&& questionList[currentindex].question.Option3"></label>
    </span>
    <span class="form-row Cm-syle">D)<label  [innerHTML]="questionList&& questionList[currentindex].question.Option4"></label>
    </span>
    <span class="form-row Cm-syle" *ngIf="questionList[currentindex].Option5 '!=null'">E)
      <label  [innerHTML]="questionList&& questionList[currentindex].Option5"></label>
    </span>

    <span class="form-row  nextSave-btn Cm-syle p-l-5 p-t-5">Correct Option:<label  [innerHTML]="questionList&& questionList[currentindex].question.CorrectOption"></label></span>
  </span>
  <span  *ngIf="questionList[currentindex].question.type=='One Word'||questionList[currentindex].question.type=='Short'||questionList[currentindex].question.type=='Long' ">
    <span class="form-row  nextSave-btn Cm-syle">Answer: <label  [innerHTML]="questionList&& questionList[currentindex].question.answer"></label></span>

  </span>
    <span  class="form-row Cm-syle m-t-5">
      Description: <label  [innerHTML]="questionList&& questionList[currentindex].question.Description"></label>
    </span>

  </div>
  <div *ngIf="editQuestionView" id="list" class="m-t-10">


        <div  class="form-row ">
          <label class="col-sm-2 col-form-label">Question: </label>
            <input type="text" class="col-sm-8  form-control" name="Question"[(ngModel)]="previewData.Question">

        </div>
        <span *ngIf="questionList[currentindex].question.type=='Multiple Choice Single Answer'">
        <div class="form-row">
          <label class="col-sm-2 col-form-label">Option1:</label>
          <input type="text" class="col-sm-8  form-control" name="Option1"[(ngModel)]="previewData.Option1">
      </div>
        <div class="form-row">
          <label class="col-sm-2 col-form-label">Option2:</label>
            <input type="text" class="col-sm-8  form-control" name="Option2"[(ngModel)]="previewData.Option2">
        </div>
        <div class="form-row ">
          <label class="col-sm-2 col-form-label">Option3:</label>
            <input type="text" class="col-sm-8  form-control" name="Option3"[(ngModel)]="previewData.Option3">
        </div>
        <div class="form-row ">
          <label class="col-sm-2 col-form-label">Option4:</label>
        <input type="text" class="col-sm-8  form-control " name="Option4"[(ngModel)]="previewData.Option4">
        </div>
        <div class="form-row " *ngIf="questionList[currentindex].Option5 '!=null'">
          <label  class="col-sm-2 col-form-label">Option5:</label>
            <input type="text" class="col-sm-8  form-control" name="Option5"[(ngModel)]="previewData.Option5">
          </div>
        <div class="form-row ">
          <label class="col-sm-2 col-form-label">Correct Option:</label>
            <input type="text" class=" col-sm-8 form-control"
            name="CorrectOption"[(ngModel)]="previewData.CorrectOption">
        </div>
 </span>

    <span class="form-row " *ngIf="questionList[currentindex].question.type=='One Word'">
      <label class="col-sm-2 col-form-label">Answer:</label>
    <input type="text" class="col-sm-8  form-control" name="oneanswer"[(ngModel)]="previewData.answer">
    </span>
    <div class="form-row ">

      <label class="col-sm-2 col-form-label">Description:</label>
      <input type="text" class="col-sm-8  form-control" name="Descriptions"[(ngModel)]="previewData.Description">

      </div>
    <span class="form-row "*ngIf="questionList[currentindex].question.type=='Short'">
      <label class="col-sm-2 col-form-label">Answer:</label>
    <textarea type="textarea" class="col-sm-8  form-control" rows="5"name="shortanswer"[(ngModel)]="previewData.answer"></textarea>
    </span>
    <span class="form-row "*ngIf="questionList[currentindex].question.type=='Long'">
      <label class="col-sm-2 col-form-label">Answer:</label>
    <textarea type="text" class="col-sm-8  form-control" name="longanswer"[(ngModel)]="previewData.answer" rows="15"></textarea>
    </span>

    <div class="row" align="end">
      <label class="col-sm-8"></label>
      <div class=" m-t-15 col-sm-3" >
        <button class="btn btn-primary m-r-5" (click)="save()">Submit</button>
        <button class="btn btn-danger" (click)="cancel()">cancel</button>
      </div>
    </div>





  </div>
</fieldset>
    <div class=" col-sm-3  custom-bag-exam">
      <div class="btn-group nextBtn " *ngFor="let item of questionList; let i = index">


        <button class="btn" type="button "
        [style.background-color]="getBackgroundColor(i)"
        (click)="onQuestion(i)">
          {{ i + 1 }}

        </button>

        <!-------------
                      [ngClass]="item.Answered ? 'answeredQu-btn':''"
                      [ngClass]="item.ReviewLater ? 'review-btn' : ''"

                      [ngClass]="item.UnAnswered ? 'unAnsweredQu-btn':''"
                      -------------------->
      </div>
    </div>
</div>
<div class="examFooter">
  <div class="row">
    <div class="col-md-7">
      <div class="btn-group" >


        <button type="button" *ngIf="currentindex !== 0" class="btn  btn-primary m-r-20" (click)="onBack()">Back</button>


        <button type="button" [disabled]="currentindex  >= questionList.length -1 "
          class="btn  nextSave-btn m-r-20" (click)="onNextClick()">
          Next
        </button>
        <button type="button"
          class="btn back-btn  m-l-20" (click)="onBackList()">
          Exit
        </button>



      </div>
    </div>
    <div class="col-md-5" align="end">

    </div>
  </div>
</div>
</div>
