export class examPreviewModel{
   id:number;
 Question:string;
   Description:string;
   CorrectOption:string;
   Option1:string;
   Option2:string;
   Option3:string;
   Option4:string;
   Option5:string;
}
export class cbtExamEditPreviewModel{
  id:number;
  Question:String=null;
  Description:string=null;
  answer:string=null;
  Option1:String=null;
  Option2:String=null;
  Option3:String=null;
  Option4: String=null;
  Option5:String=null;

  CorrectOption:string=null;
}
