<div class="card">
  <div class="header-custom">
        <h1 >{{questionArray&& questionArray.examName}}</h1>
  </div>
  <div class="row examBody">
    <div class="form-group col-sm-9">
      <div class="card-body">
        <div

          *ngIf="currentindex <= questionList.length - 1; let i = index"
        >
          <label class="form-group col-6"
            >Question {{  currentindex +1  }})</label
          >
          <label class="form-group col-6">Mark(s): 1</label>
        </div>

        <fieldset class="row">
          <div class="m-l-10 col-sm-12  cnt-bdy_style ">
            <label class="form-group" [innerHTML]="questionList&& questionList[currentindex].question.Question"
              >
               ?</label
            >
            <span  class="form-row m-t-5" *ngIf="questionList[currentindex].question.filePath!=null">
              <img  [src]="urlpath+questionList[currentindex].question.filePath" class=" form-row prf_img">

            </span>
                <div class="form-group col-sm-9 m-l-10"
                >
                <span *ngIf="questionList[currentindex].question.type=='Multiple Choice Single Answer'">
              <div class="form-check" [ngStyle]="getColor(currentindex,1)">
                <input
                  class="form-check-input"

                  type="radio"
                  name="gridRadios"
                  [(ngModel)]="selectedoption"
                  id="gridRadios1"
                 [value]="1"
                  (change)="onSelect(selectedoption)"
                />
                <label class="form-check-label" for="gridRadios1"
                  >A)  {{ questionList&& questionList[currentindex].question.Option1 }}

                  </label
                >
              </div>
              <div class="form-check"
              [ngStyle]="getColor(currentindex,2)">
                <input
                  class="form-check-input"
                  type="radio"
                  name="gridRadios"
                  [(ngModel)]="selectedoption"
                  id="gridRadios2"
                  [value]="2"
                  (change)="onSelect(selectedoption)"

                />
                <label class="form-check-label" for="gridRadios2">
                  B)  {{ questionList&& questionList[currentindex].question.Option2 }}.</label
                >
              </div>
              <div class="form-check"
              [ngStyle]="getColor(currentindex,3)">
                <input
                  class="form-check-input"
                  type="radio"
                  name="gridRadios"
                  [(ngModel)]="selectedoption"
                  id="gridRadios3"
                  [value]="3"
                  (change)="onSelect(selectedoption)"
                />
                <label class="form-check-label" for="gridRadios3"
                  >C)  {{ questionList&& questionList[currentindex].question.Option3 }}.</label
                >
              </div>
              <div class="form-check"
              [ngStyle]="getColor(currentindex,4)">
                <input
                  class="form-check-input"
                  type="radio"
                  name="gridRadios"
                  [(ngModel)]="selectedoption"
                  id="gridRadios4"
                  [value]="4"
                  (change)="onSelect(selectedoption)"
                />
                <label class="form-check-label" for="gridRadios4"
                  >D)  {{ questionList&& questionList[currentindex].question.Option4 }}.</label
                >
              </div>
              <div class="form-check" *ngIf="questionList[currentindex].question.Option5 '!=null'"
              [ngStyle]="getColor(currentindex,5)"
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="gridRadios"
                  [(ngModel)]="selectedoption"
                  id="gridRadios5"
                  [value]="5"
                  (change)="onSelect(selectedoption)"
                />
                <label class="form-check-label" for="gridRadios5"
                  >E)  {{ questionList&& questionList[currentindex].question.Option5 }} .</label
                >
              </div>
              <div class="m-t-5">
                <label
                *ngIf="questionList[this.currentindex].question.chosenAnswer !=null &&
                this.questionList[this.currentindex].question.chosenAnswer != questionList[currentindex].question.CorrectOption"
                class="form-row  nextSave-btn">Correct Option: {{ questionList&& questionList[currentindex].question.CorrectOption }} </label>
                <label
                *ngIf="  this.questionList[this.currentindex].question.chosenAnswer"
                class="form-row ">
                  Description: {{ questionList&& questionList[currentindex].question.Description}}.</label>
              </div>
            </span>
            </div>
          <span  *ngIf="questionList[currentindex].question.type=='One Word'">
            <div class="row" >
              <label class="row col-form-label">Answer</label><br>
              <input
              class="form-control "
              type="text"
              name="gridRadios"
              [(ngModel)]="questionList[this.currentindex].question.chosenAnswer"
              id="gridRadios5"
              name="oneword"
            />  <br>
            <div>
              <button class="btn btn-primary " (click)="onewordSumbit(questionList[this.currentindex].question)">sumbit</button>

            </div>
            </div>
          <div *ngIf="IsOneWord">
            <span
            *ngIf="questionList[this.currentindex].question.answer ==questionList[this.currentindex].question.cho"></span>
          <label class="col-sm-2 col-form-label">Answer:</label>
          <label  class="col-sm-8 form-control" name="oneanswer"  [innerHTML]="questionList[currentindex].question.answer"></label>
         </div>
        </span>
          <span *ngIf="questionList[currentindex].question.type=='Short'">

          <div >
          <label class="row col-form-label">Answer:</label>
          <label  class="row" name="shortanswer"
          [innerHTML]="questionList[currentindex].question.answer"></label>
        </div>
        </span>
          <span *ngIf="questionList[currentindex].question.type=='Long'">

          <div >
          <label class="row col-form-label">Answer:</label>
          <label  class="row" name="longanswer" [innerHTML]="questionList[currentindex].question.answer"></label>
        </div>
        </span>

          </div>
        </fieldset>
      </div>
    </div>
    <div class="col-sm-3 custom-bag-exam">
      <div style="text-align: center"></div>
      <div class="">
        <div class="btn-toolbar">
          <div
            class="btn-group nextBtn "
            *ngFor="let item of questionList; let i = index"
          >


          <button
          class=  "btn"
          type="button "
           [style.background-color]="getBackgroundColor(i)"

              (click)="onQuestion(i)"

              >
              {{ i + 1 }}

            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="examFooter">
    <div class="row">
      <div class="col-md-7">
        <div
          class="btn-group1"
          *ngIf="currentindex <= questionList.length - 1; let i = index"
        >


          <button type="button"
          *ngIf="currentindex !== 0"
          class="btn  btn-primary" (click)="onBack()">Back</button>



          <!-- <button type="button" *ngIf="currentindex == questionList.length - 1" class="btn btn-sm nextSave-btn m-l-20">Finished</button> -->

          <button
            type="button"
            class="btn  nextSave-btn"
           *ngIf="currentindex  <  questionList.length - 1 "
            (click)="onSaveNextClick()"
          >
           Next
          </button>


          <button type="button" class="btn back-btn" (click)="onBackList()">
           Back to List
          </button>

        </div>
      </div>

    </div>
  </div>
</div>
