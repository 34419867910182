export class questionModel{

   QuestionId:Number;
  ///  Description:String;
    // CorrectOption:String;
    selectedoption:String;
    // Option1:String;
    // Option2:String;
    // Option3:String;
    // Option4: String;
    // Option5:String

}
export class McqQuestionModel{

    Question:Number;
    Description:String;
    CorrectOption:String;
    Option1:String;
    Option2:String;
    Option3:String;
    Option4: String;
    Option5:String;
    id:number;

}
